<template>
  <div>
    <div class="flex flex-col hidden md:block">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full animate-pulse divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 w-60 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span class="bg-gray-200 h-4 w-24 block rounded-md">&nbsp;</span>
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span class="bg-gray-200 h-4 w-24 block rounded-md">&nbsp;</span>
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span class="bg-gray-200 h-4 w-24 block rounded-md">&nbsp;</span>
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span class="bg-gray-200 h-4 w-24 block rounded-md">&nbsp;</span>
                </th>
                <th scope="col" class="relative px-6 py-3">
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 w-22 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-22 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 w-60 whitespace-nowrap text-right text-sm font-medium">
                </td>
              </tr>
              <tr class="bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 w-22 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-22 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 w-60 whitespace-nowrap text-right text-sm font-medium">
                </td>
              </tr>
              <tr class="bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-60 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 w-22 whitespace-nowrap text-sm text-gray-500">
                  <span class="bg-gray-200 h-4 w-22 block rounded-md">&nbsp;</span>
                </td>
                <td class="px-6 py-4 w-60 whitespace-nowrap text-right text-sm font-medium">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="md:hidden">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 relative">
        <div class="block">
          <div class="py-4 border-b">
            <div class="grid grid-cols-2 gap-2 cursor-pointer">
              <div>
                <div class="flex">
                  <div>
                    <div class="w-12 ml-4 bg-gray-300 h-12 rounded-full "></div>
                  </div>
                  <div class="pl-2">
                    <span class="bg-gray-200 h-4 w-22 block rounded-md">&nbsp;</span>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex">
                  <div class="flex-1">
                    <span class="bg-gray-200 h-4 w-22 block rounded-md">&nbsp;</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="py-4 border-b">
            <div class="grid grid-cols-2 gap-2 cursor-pointer">
              <div>
                <div class="flex">
                  <div>
                    <div class="w-12 ml-4 bg-gray-300 h-12 rounded-full "></div>
                  </div>
                  <div class="pl-2">
                    <span class="bg-gray-200 h-4 w-22 block rounded-md">&nbsp;</span>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex">
                  <div class="flex-1">
                    <span class="bg-gray-200 h-4 w-22 block rounded-md">&nbsp;</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Table.skeleton.vue"
}
</script>

<style scoped>

</style>
