<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <div v-if="employees && employees.length > 0" class="block border bg-white rounded-2xl p-4">
      <div class="divide-y w-full">
        <div class="bg-gray-50 grid gap-3 grid-cols-8">
          <div class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Id
          </div>
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Name
          </div>
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Position held
          </div>
          <div class="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          </div>
        </div>
        <div class="block">
          <div v-for="(employee, key) in employees" :key="key">
            <div class="grid gap-3 grid-cols-8 bg-white cursor-pointer hover:bg-gray-50">
              <div class="md:px-2 pt-4 pb-2 whitespace-nowrap text-sm font-medium text-gray-800">
                <span>{{ employee.id }}</span>
              </div>
              <div class="py-4 whitespace-nowrap text-sm text-gray-500">
                <span>
                  {{ employee.name }}
                </span>
              </div>
              <div class="py-4 whitespace-nowrap text-sm text-gray-500">
                <span>
                  {{ employee.positionHeld }}
                </span>
              </div>
              <div class="pt-4 pb-2 truncate whitespace-nowrap text-sm font-medium text-gray-800">
                {{ employee.ownerEmail }}
              </div>
              <div class="py-4 whitespace-nowrap text-sm text-gray-500">
                <div class="flex">
                  <div>
                    <router-link :to="{ name: 'EmployeeForm', params: { id: employee.id } }"
                      class="text-blue-500 hover:text-blue-600">
                      <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                        <span aria-hidden="" class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                        <span class="relative">Edit</span>
                      </span>
                    </router-link>
                  </div>
                  <div class="pl-2">
                    <div>
                      <router-link :to="{ name: 'EmployeeDetail', params: { id: employee.id } }"
                        class="text-blue-500 hover:text-blue-600">
                        <span class="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                          <span aria-hidden="" class="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                          <span class="relative">Show</span>
                        </span>

                      </router-link>
                    </div>
                    <!-- <button v-on:click="remove(tx.id, tx.txHash)" class="bg-red-500 p-1 rounded-md hover:bg-red-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <h3 class="mt-2 text-sm font-medium text-gray-900">No Employees</h3>
    </div>
  </div>
</template>

<script>
import EmployeeService from '@/service/employee'
export default {
  name: 'Employees',
  data: () => ({
    loaded: false,
    employees: [],
  }),
  beforeMount: async function () {
    await this.initPage();
    this.loaded = true;
  },
  methods: {
    initPage: async function () {
      await this.getAll();
    },
    getAll: async function () {
      const service = new EmployeeService();
      await service.all()
        .then((response) => {
          this.employees = response.data.items;
          console.log(this.employees)
        });

    },
  }
}
</script>