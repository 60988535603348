<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <div class="block border bg-white rounded-2xl p-4">
      <div class="space-y-8 divide-y divide-gray-200">
        <div class="">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Add Payment
          </h3>
          <div class="block mt-2">
            <div class="block max-w-lg">
                  <label :for="payment.name">Name</label>
                  <input v-model="payment.name"
                         type="text"
                         required
                         placeholder="Payment name"
                         class="block w-full mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">

            </div>
            <div class="block mt-2 max-w-lg">
              <label :for="payment.type">Type</label>
              <select required v-model="payment.type" v-on:change="selectPayment($event)" class="max-w-lg mt-1 block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                <option v-for="(method, key) in paymentMethods" :key="key" :value="method.id">{{method.name}}</option>
              </select>
            </div>
            <div v-if="payment.type === 2" class="block mt-2 max-w-lg">
              <label :for="payment.reference">Reference</label>
              <input v-model="payment.reference"
                     type="text"
                     required
                     placeholder="Payment reference"
                     class="block w-full mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">

            </div>
            <div v-if="payment.type === 0" class="block mt-2 max-w-lg">
              <label :for="payment.chainType">Chain</label>
              <select required v-model="payment.chainType" class="max-w-lg mt-1 block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                <option v-for="(method, key) in chains" :key="key" :value="method.id">{{method.name}}</option>
              </select>
            </div>
            <div v-if="payment.type === 0" class="block mt-2 max-w-lg">
              <label :for="txHash">Select matching tx hash</label>
              <div v-if="selectedTxHash.txHash !== null && selectedTxHash.id !== null" class="max-w-lg block">
                Currently selected tx hash: <span class="text-blue-500">{{ selectedTxHash.txHash }}</span>
              </div>
              <div class="max-w-lg mt-2 block">
                <input v-model="txHash"
                       v-on:input="searchTxHash"
                       v-on:focus="searchTxHash"
                       type="text"
                       required
                       placeholder="Transaction hash"
                       class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">

                <div class="block mt-2" v-if="hashResults.length > 0">
                  <div class="block" v-for="(hash, key) in hashResults" :key="key">
                    <div v-on:click="selectReceiptTxHash(hash)" class="py-2 px-2 border-b border-t hover:bg-gray-200 cursor-pointer">
                      {{ hash.txHash }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block mt-2 max-w-lg w-full relative focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
              <div class="flex">
                <div>
                  <label :for="payment.value">Value</label>
                  <input type="number" v-model="payment.value" class="focus:ring-indigo-500 z-0 mt-1 focus:border-indigo-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
                </div>
                <div class="pl-3">
                  <div class="block">
                    <label class="block">Currency</label>
                    <select v-model="currency" class="select border-gray-300 rounded-md">
                      <option class="text-gray-500 mt-4 sm:text-sm" :value="0">AED</option>
                      <option class="text-gray-500 mt-4 sm:text-sm" :value="1">USD</option>
                      <option class="text-gray-500 mt-4 sm:text-sm" :value="2">EUR</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="payment.type === 0" class="block mt-2 max-w-lg w-full relative shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
              <label :for="payment.amount">Crypto amount</label>
              <div class="absolute inset-y-0 mt-2 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 mt-4 sm:text-sm">
                  <div v-if="payment.chainType === 0">
                    <img src="@/assets/icons/btc.svg" class="w-6"/>
                  </div>
                  <div v-if="payment.chainType === 1">
                    <img src="@/assets/icons/eth.svg" class="w-6"/>
                  </div>
                  <div v-if="payment.chainType === 2">
                    <img src="@/assets/icons/bnb.svg" class="w-6"/>
                  </div>
                  <div v-if="payment.chainType === 3">
                    <img src="@/assets/icons/tron.svg" class="w-6"/>
                  </div>
                  <div v-if="payment.chainType === 4">
                    <img src="@/assets/icons/sol.svg" class="w-6"/>
                  </div>
                </span>
              </div>
              <input required type="number" v-model="payment.amount" class="focus:ring-indigo-500 mt-2 focus:border-indigo-500 block w-full pl-10 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
              <div class="absolute inset-y-0 mt-4 right-0 pr-3 flex items-center pointer-events-none">
                <span v-if="payment.chainType === 0" class="text-gray-500 sm:text-sm">
                  BTC
                </span>
                <span v-if="payment.chainType === 1" class="text-gray-500 sm:text-sm">
                  ETH
                </span>
                <span v-if="payment.chainType === 2" class="text-gray-500 sm:text-sm">
                  BNB
                </span>
                <span v-if="payment.chainType === 3" class="text-gray-500 sm:text-sm">
                  TRX
                </span>
                <span v-if="payment.chainType === 4" class="text-gray-500 sm:text-sm">
                  SOL
                </span>
              </div>
            </div>
          </div>
          <div class="block mt-2">
            <button v-if="(!loading && payment.name !== '' && payment.value !== null && (payment.type === 1 || payment.type === 3)) ||
            (!loading && payment.name !== '' && payment.value !== null && payment.type === 0 && payment.amount !== null && selectedTxHash.txHash !== null && selectedTxHash.id !== null) ||
            (!loading && payment.name !== '' && payment.value !== null && payment.type === 2 && payment.reference !== null)"
                    v-on:click="addPayment()" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Save
            </button>
            <button v-else-if="loading" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
              Save
            </button>
            <button v-else class="group cursor-not-allowed opacity-70 relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 focus:outline-none ">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HashService from "@/service/hash";
import PaymentService from "@/service/payment";

export default {
  name: "PaymentCreate",
  data: () => ({
    payment: {
      name: '',
      type: 0,
      txHashId: null,
      reference: null,
      amount: null,
      value: null,
      chainType: 0
    },
    txHash: '',
    txHashes: [],
    hashResults: [],
    selectedTxHash: {
      hash: null,
      id: null
    },
    paymentMethods: [
      {
        id: 5,
        name: 'Crypto'
      },
      {
        id: 0,
        name: 'Cash'
      },
      {
        id: 3,
        name: 'Wire Transfer'
      },
      {
        id: 2,
        name: 'Cheque'
      },
      {
        id: 4,
        name: 'Cashier\'s check'
      }
    ],
    currency: 0,
    chains: [
      {
        id: 0,
        name: 'BTC'
      },
      {
        id: 1,
        name: 'ETH'
      },
      {
        id: 2,
        name: 'BSC'
      },
      {
        id: 3,
        name: "TRX"
      }
    ],
    loading: false,
  }),
  beforeMount: async function() {
    await this.getTxHashes();
  },
  methods: {
    addPayment: async function() {
      this.loading = !this.loading;

      const service = new PaymentService();
      let dto = {};
      // Crypto payment
      if (this.payment.type === 5) {
        dto = {
          Name: this.payment.name,
          Type: this.payment.type,
          ChainType: this.payment.chainType,
          Value: this.payment.value,
          Amount: this.payment.amount,
          TxHashId: this.selectedTxHash.id,
          Reference: '',
          Currency: this.currency
        }
      } else if (this.payment.type === 0) {
        // Cash
        dto = {
          Name: this.payment.name,
          Type: this.payment.type,
          Value: this.payment.value,
          Reference: '',
          Currency: this.currency
        }
      } else if (this.payment.type === 3) {
        // Wire Transfer
        dto = {
          Name: this.payment.name,
          Type: this.payment.type,
          Value: this.payment.value,
          Reference: this.payment.reference,
          Currency: this.currency
        }
      } else if (this.payment.type === 2) {
        // Cheque Transfer
        dto = {
          Name: this.payment.name,
          Type: this.payment.type,
          Value: this.payment.value,
          Reference: '',
          Currency: this.currency
        }
      }

      await service.add(dto)
        .then(async () => {
           this.$notifications(
            'Payment Method Added',
            '',
            '',
            0,
            true
          );
        }).catch((e) => {
          console.log(e);
          console.log(e.request)
        }).finally(() => {
            this.loading = !this.loading;
            this.$router.push({name: 'Payment'});
        });
    },
    selectPayment: function(event) {
      this.paymentMethods.forEach(payment => {
        if (Number(payment.id) === Number(event.target.value))
          this.payment.type = payment.id;
      });
    },
    getTxHashes: async function() {
      const service = new HashService();
      await service.all()
        .then((response) => {
          this.txHashes = response.data;
        });
    },
    searchTxHash: async function() {
      if (this.txHash.length > 2) {
        this.hashResults = [];
        const hash = this.txHash.toLowerCase();
        this.txHashes.forEach(v => {
          let ve  = v.txHash.toLowerCase();
          if (ve.includes(hash)) {
            if (this.hashResults.length > 0 && !this.hashResults.includes(v)) {
              this.hashResults.push(v);
            } else if (!this.hashResults.includes(v)) {
              this.hashResults.push(v);
            }
          }
        });
      } else {
        this.hashResults = this.txHashes;
      }
    },
    selectReceiptTxHash: function(hash) {
    
      this.selectedTxHash = hash;
      this.txHash = '';
      this.hashResults = [];
    },
    truncateString: function(str, num) {
      if (str !== undefined) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
      }
    },
  }
}
</script>

<style scoped>

</style>
