import axios from "axios";
import store from "@/store";

export default class SearchService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
        }
    }

    async getSearchResults(searchQuery: string) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/search/search?searchTerm=${searchQuery}&page=1&pageSize=12`);
    }
}
