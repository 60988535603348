<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <div class="block border bg-white rounded-2xl p-4">
      <span v-if="payments.length < 1">No Payments</span>
      <div v-if="payments.length > 0" class="block">
        <PaymentTable class="hidden md:block"
                      :paymentsArray="payments"
                      :pagination="pagination"
                      @getPayments="getAll" />
        <PaymentTableMobile class="md:hidden"
                      :paymentsArray="payments"
                      :pagination="pagination"
                      @getPayments="getAll" />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentService from "@/service/payment";
import PaymentTable from "@/components/tables/Payment";
import PaymentTableMobile from "@/components/tables/mobile/Payment";
import FormatHelper from "@/helpers/format";

export default {
  name: "Payment",
  components: {PaymentTable, PaymentTableMobile},
  data: () => ({
    payments: [],
    page: 1,
    pageSize: 25,
    pagination: {
      show: 1,
      to: 25,
      totalItems: 0,
      totalPages: 1,
    },
    loading: false,
  }),
  beforeMount: async function() {
    await this.getAll();
  },
  methods: {
    getAll: async function() {
      const service = new PaymentService();

      await service.all(this.page, this.pageSize)
        .then((response) => {
          this.setCurrencyValues(response.data.items);
          this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
        });
    },
    setCurrencyValues: async function(payments) {
      this.payments = [];
      const helper = new FormatHelper();
      this.payments = await helper.formatCurrencies(payments);
    },
    setPagination: function(page, pageSize, totalItems) {
      this.page = page;
      this.pageSize = pageSize;
      this.pagination.totalItems = totalItems;

      this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pageSize);
    },
    truncateString: function(str, num) {
      if (str !== undefined) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
      }
    },
  }
}
</script>

<style scoped>

</style>
