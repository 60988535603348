import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      is_staff: false,
      user_id: null,
      username: null,
      email: null,
    },
    bearerToken: '',
    refreshToken: '',
    authenticated: false,
    conversion: {
      aed: {
        eur: 0,
        usd: 0,
      },
      usd: {
        aed: 0,
        eur: 0
      },
      eur: {
        aed: 0,
        usd: 0,
      }
    },
    firstSignIn: true,
  },
  getters: {
    user: state => {
      return state.user
    },
    bearerToken: state => {
      return state.bearerToken
    },
    refreshToken: state => {
      return state.refreshToken
    },
    authenticated: state => {
      return state.authenticated
    },
    conversion: state => {
      return state.conversion
    },
    firstSignIn: state => {
      return state.firstSignIn
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
