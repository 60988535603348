<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <div class="block border bg-white rounded-2xl p-4">
      <div class="space-y-8 divide-y divide-gray-200">
        <div class="">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Add Vendor
          </h3>
          <div class="block mt-6">
            <div v-if="accounts.length > 0" class="block">
              <select v-on:change="selectAccount($event)" v-model="accountId" class="w-full block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                <option selected :value="null">Select Account (optional)</option>
                <option v-for="(account, key) in accounts" :key="key" :value="account.id">{{account.username}}: {{account.email}}</option>
              </select>
            </div>
            <div :class="{'flex mt-2': accounts && accounts.length > 0, 'flex': accounts && accounts.length < 1}">
              <div class="flex-1">
                <input v-model="vendor"
                       type="text"
                       required
                       placeholder="Create New Vendor"
                       class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
              </div>
              <div class="pl-5">
                <button v-if="vendor !== '' && !loading" v-on:click="createVendor" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md border-gray-800 hover:bg-gray-800 hover:text-white">Create vendor</button>
                <button v-else-if="vendor !== '' && loading" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                  Create Vendor
                </button>
                <button v-else class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium rounded-md text-gray-800 bg-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">Create vendor</button>
              </div>
            </div>
          </div>
        </div>
        <div class="sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <span v-if="vendors.length < 1">No Vendors</span>
          <div v-if="vendors.length > 0" class="block">
            <div class="block mb-2 border-b pb-2" v-for="(vendor, key) in vendors" :key="key">
              <div class="flex">
                <div class="flex-1">
                  <span class="leading-6 font-medium text-gray-900">{{ vendor.name }}</span>
                </div>
                <div>
                  <button v-on:click="remove(vendor.id, vendor)" class="bg-red-500 p-1 rounded-md hover:bg-red-600">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Usage v-if="showUsageModal" :dto="dto" :type="type" :usages="usages" @toggleModal="toggleModal" />
  </div>
</template>

<script>
import VendorService from "@/service/vendor";
import Usage from "@/components/modals/receipt/Usage";
import ReceiptService from "@/service/receipt";
import AccountService from "@/service/account";

export default {
  name: "Vendors",
  components: {Usage},
  data: () => ({
    accountId: null,
    accounts: [],
    vendor: '',
    vendors: [],
    loading: false,
    dto: {},
    usages: [],
    type: 1,
    showUsageModal: false
  }),
  beforeMount: function() {
    this.getAll();
    this.getAccountRights();
  },
  methods: {
    getAccountRights: async function() {
      const service = new AccountService();
      // get list of accounts with edit rights for your company
      await service.getAccountRights().then(response => {
        this.accounts = response.data;
      });
    },
    selectAccount: function($event) {
      this.accountId = $event.target.value;
    },
    getAll: async function() {
      const service = new VendorService();

      await service.all()
        .then((response) => {
          this.vendors = response.data;
        });
    },
    createVendor: async function() {
      this.loading = !this.loading;
      const service = new VendorService();
      const dto = {
        name: this.vendor,
        ownerAccountId: this.accountId,
      }

      await service.add(dto)
          .then(async () => {
            await this.getAll();
          }).catch((e) => {
            console.log(e);
          }).finally(() => {
            this.vendor = '';
            this.loading = !this.loading;
          });
    },
    remove: async function(id, dto) {
      // check if it is used in a receipt
      const service = new VendorService();
      const receiptService = new ReceiptService();
      await receiptService.typeInReceipt(3, id)
          .then((response) => {
            if (response.data.length > 0) {
              this.usages = response.data;
              this.dto = dto;
              this.showUsageModal = true;
            } else {
              // delete
              service.delete(id)
                  .then(() => {
                    this.$notifications('Vendor Deleted', 'Successfully deleted the vendor', '', 0, true);
                    this.getAll();
                  })
            }
          });
    },
    toggleModal: function() {
      this.showUsageModal = !this.showUsageModal;
    }
  }
}
</script>

<style scoped>

</style>
