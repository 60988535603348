<template>
  <div id="#login" class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="h-12 mx-auto w-auto" src="@/assets/logo.png" alt="Accountant Chain">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>
      <div class="-space-y-px">
        <div>
          <label for="password" class="sr-only">Password</label>
          <div class="relative rounded-md shadow-sm">
            <input id="password"
                   v-model="password"
                   name="password"
                   type="password"
                   required
                   class="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                   placeholder="Password">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
              <svg v-if="!showPassword" v-on:click="togglePasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
              <svg v-else v-on:click="togglePasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
              </svg>
            </div>
          </div>
        </div>
        <div>
          <label for="passwordConfirm" class="sr-only">Confirm Password</label>
          <div class="relative rounded-md shadow-sm">
            <input id="passwordConfirm" v-model="passwordConfirm" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" placeholder="Confirm password">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
              <svg v-if="!showConfirmPassword" v-on:click="toggleConfirmPasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
              <svg v-else v-on:click="toggleConfirmPasswordType" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
              </svg>
            </div>
          </div>
          <div v-if="error.password !== null" class="mt-3 text-sm">
            <a href="#" class="font-medium text-red-500">{{error.password}}</a>
          </div>
          <div v-if="error.password === null && password.length >= 8 && passwordConfirm.length >= 5 && password !== passwordConfirm" class="mt-3 text-sm">
            <a href="#" class="font-medium text-red-500">Password and password confirm are not the same</a>
          </div>
        </div>
      </div>
      <div>
        <button v-if="isValidPassword && isValidConfirmPassword  && !loading" v-on:click="resetPassword" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Reset password
        </button>
        <button v-else-if="isValidPassword && isValidConfirmPassword  && loading" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
          Reset password
        </button>
        <button v-else class="group cursor-not-allowed relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-300">
          Reset password
        </button>
        <div class="items-center mt-6">
          <div class="text-sm text-center">
            <router-link :to="{name: 'Homepage'}" class="font-medium text-blue-500 hover:text-blue-600">
              Back to homepage
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AuthenticateService from "@/service/authenticate";

export default Vue.extend({
  name: "ResetPassword.vue",
  data: () => ({
    token: null,
    loading: false,
    password: '',
    passwordConfirm: '',
    isValidPassword: false,
    showPassword: false,
    showConfirmPassword: false,
    isValidConfirmPassword: false,
    error: {
      password: null,
      passwordConfirm: null,
    }
  }),
  mounted: function() {
    this.token = this.$route.query.token;
  },
  methods: {
    resetPassword: async function() {
      this.loading = true;
      const dto = {
        password: this.password,
        confirmPassword: this.passwordConfirm,
        token: this.token,
      }

      const service = new AuthenticateService();
      await service.setPassword(dto)
        .then(() => {
          this.$notifications(
              'Password Reset Successful',
              'Your password is changed. You can sign in now.',
              '',
              0,
              true
          );
          this.$router.push({name: 'Login'});
        }).catch((e) => {
          this.$notifications(
              'Internal Server Error',
              `Something went wrong logging in. Status code: 500`,
              '',
              1,
              true
          );
        }).finally(() => {
          this.loading = false;
        });
    },
    togglePasswordType: function() {
      this.showPassword = !this.showPassword;
      const type = document.getElementById('password').getAttribute('type');
      if (type === 'password')
        document.getElementById('password').setAttribute('type', 'text');
      else
        document.getElementById('password').setAttribute('type', 'password');
    },
    toggleConfirmPasswordType: function() {
      this.showConfirmPassword = !this.showConfirmPassword;
      const type = document.getElementById('passwordConfirm').getAttribute('type');
      if (type === 'password')
        document.getElementById('passwordConfirm').setAttribute('type', 'text');
      else
        document.getElementById('passwordConfirm').setAttribute('type', 'password');
    }
  },
  watch: {
    password: {
      handler: function() {
        /**
         * Password validation RegEx for JavaScript
         *
         * Passwords must be
         * - At least 8 characters long, max length anything
         * - Include at least 1 lowercase letter
         * - 1 capital letter
         * - 1 number
         * - 1 special character => !@#$%^&*{}();',.<>?~`+_-
         *
         *
         */
        const passwordIsValid = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*{}();',.<>?~`+_-])[\w!@#$%^&*{}();',.<>?~`+_-]{8,}$/.test(this.password);
        // const regex = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/;
        if (this.password.length > 4 && !passwordIsValid) {
          this.error.password = "The password should be at least 8 characters long, include 1 lowercase letter, 1 capital letter, 1 number and 1 special character";
        } else {
          this.error.password = null;
          this.isValidPassword = true;
        }
      }
    },
    passwordConfirm: {
      handler: function() {
        if (this.password === this.passwordConfirm) {
          this.isValidConfirmPassword = true;
        } else {
          this.isValidConfirmPassword = false;
        }
      }
    }
  }
});
</script>
