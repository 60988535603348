<template>
  <div class="receipt">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 relative mt-6">
      <SkeletonTable v-if="!loaded" />
      <div v-if="receipts.length > 0 && loaded">
        <div class="pb-4">
          <h3 class="text-2xl font-bold">Your Receipts</h3>
        </div>

        <ReceiptTable
            class="hidden md:block"
            :receiptsArray="receipts"
            @getReceipts="getAccountReceipts"
            :pagination="pagination"
            :isLoadingNext="isLoadingNext"
            :isLoadingPrevious="isLoadingPrevious"
            :conversion_rates="conversion_rates"/>
        <ReceiptMobile
            class="md:hidden"
            :receiptsArray="receipts"
            @getReceipts="getAccountReceipts"
            :pagination="pagination"
            :isLoadingNext="isLoadingNext"
            :isLoadingPrevious="isLoadingPrevious"
            :conversion_rates="conversion_rates"/>

      </div>
      <div v-else-if="loaded" class="pb-4">
        <div class="text-center">
          <h3 class="mt-2 text-sm font-medium text-gray-900">No Receipts added</h3>
          <p class="mt-1 text-sm text-gray-500">
            Get started by creating a new receipt.
          </p>
          <div class="mt-6">
            <router-link :to="{name: 'ReceiptCreate'}"  type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
              </svg>
              Add Receipt
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReceiptService from "@/service/receipt";
import ReceiptTable from '@/components/tables/Receipt';
import ReceiptMobile from '@/components/tables/mobile/Receipt';
import SkeletonTable from '@/components/skeletons/Table';
import store from "@/store";
import BusinessService from "@/service/business";
import FormatHelper from "@/helpers/format";

export default {
  name: "ReceiptAccount",
  components: {
    ReceiptTable,
    ReceiptMobile,
    SkeletonTable,
  },
  data: () => ({
    receipts: [],
    page: 1,
    pageSize: 100,
    pagination: {
      show: 1,
      to: 100,
      totalItems: 0,
      pages: [],
      totalPages: 1,
    },
    isLoadingNext: false,
    isLoadingPrevious: false,
    conversion_rates: {
      eur: store.getters.conversion.eur,
      usd: store.getters.conversion.usd
    },
    loaded: false
  }),
  created: function() {
    if (store.getters.firstSignIn) {
      this.getAccountCompanies();
    }
  },
  beforeMount: async function() {
    if (this.$route.name === 'ReceiptExpenses') {
      await this.getExpenseReceipts(this.page, this.pageSize, false, false);
    } else if (this.$route.name === 'ReceiptAssets') {
      await this.getAssetReceipts(this.page, this.pageSize, false, false);
    } else {
      await this.getAccountReceipts(this.page, this.pageSize, false, false);
    }

    this.loaded = true;
  },
  methods: {
    getAccountCompanies: async function() {
      const service = new BusinessService();
      await service.getAccountCompanies(1, 12)
        .then((response) => {
          if (response.data.items.length < 1) {
            store.state.firstSignIn = true;
            this.$router.push({name: 'Business'});
          } else {
            store.state.firstSignIn = false;
          }
        }).catch((e) => {
          console.log(e.request)
          console.log(`there is an error`, e);
        });
    },
    getAccountReceipts: async function(page, pageSize, loadingNext, loadingPrevious) {
      if (loadingNext) {
        this.isLoadingNext = !this.isLoadingNext;
      } else if (loadingPrevious) {
        this.isLoadingPrevious = !this.isLoadingPrevious;
      }
      const service = new ReceiptService();
        await service.getAllForAccount(page, pageSize, this.$route.params.txid)
        .then((response) => {
          this.setCurrencyValues(response.data.items);
          this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
        }).catch((e) => {
          console.log(e.request)
          console.log(`there is an error`, e);
        }).finally(() => {
          if (loadingNext) {
            this.isLoadingNext = !this.isLoadingNext;
          } else if (loadingPrevious) {
            this.isLoadingPrevious = !this.isLoadingPrevious;
          }
        });
    },
    getAssetReceipts: async function(page, pageSize, loadingNext, loadingPrevious) {
      if (loadingNext) {
        this.isLoadingNext = !this.isLoadingNext;
      } else if (loadingPrevious) {
        this.isLoadingPrevious = !this.isLoadingPrevious;
      }
      const service = new ReceiptService();
      await service.getAllAssetsForAccount(page, pageSize)
          .then((response) => {
            this.setCurrencyValues(response.data.items);
            this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
          }).catch((e) => {
            console.log(e.request)
            console.log(`there is an error`, e);
          }).finally(() => {
            if (loadingNext) {
              this.isLoadingNext = !this.isLoadingNext;
            } else if (loadingPrevious) {
              this.isLoadingPrevious = !this.isLoadingPrevious;
            }
          });
    },
    getExpenseReceipts: async function(page, pageSize, loadingNext, loadingPrevious) {
      if (loadingNext) {
        this.isLoadingNext = !this.isLoadingNext;
      } else if (loadingPrevious) {
        this.isLoadingPrevious = !this.isLoadingPrevious;
      }
      const service = new ReceiptService();
      await service.getAllExpensesForAccount(page, pageSize)
          .then((response) => {
            this.setCurrencyValues(response.data.items);
            this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
          }).catch((e) => {
            console.log(e.request)
            console.log(`there is an error`, e);
          }).finally(() => {
            if (loadingNext) {
              this.isLoadingNext = !this.isLoadingNext;
            } else if (loadingPrevious) {
              this.isLoadingPrevious = !this.isLoadingPrevious;
            }
          });
    },
    setCurrencyValues: async function(receipts) {
      const helper = new FormatHelper();
      this.receipts = await helper.formatCurrencies(receipts);
    },
    setPagination: function(page, pageSize, totalItems) {
      this.page = page;
      this.pageSize = pageSize;
      this.pagination.totalItems = totalItems;

      const pages = Math.ceil(this.pagination.totalItems / this.pageSize);
      this.pagination.pages = [];
      for (let i = 0; i < pages; i++) {
        this.pagination.pages.push(i+1);
      }

      this.pagination.totalPages = pages;
    },
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.name === 'ReceiptExpenses') {
          this.getExpenseReceipts(this.page, this.pageSize, false, false);
        } else if (route.name === 'ReceiptAssets') {
          this.getAssetReceipts(this.page, this.pageSize, false, false);
        } else {
          this.getAccountReceipts(this.page, this.pageSize, false, false);
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
