import store from "@/store";

export default class FormatHelper {
    private conversion_rates: any;

    constructor() {
        this.conversion_rates = store.getters.conversion;
    }

    async formatTxCurrencies(items: any[]) {
        for (const item of items) {
            if (item.currency) {
                if (item.currency === 0) {
                    // AED
                    item.currencyValue = {
                        aed: await this.formatFromAed(item.amount, 3),
                        usd: await this.formatFromAed(item.amount, 1),
                        eur: await this.formatFromAed(item.amount, 2),
                    };
                } else if (item.currency === 1) {
                    // USD
                    item.currencyValue = {
                        aed: await this.formatFromDollar(item.amount, 3),
                        usd: await this.formatFromDollar(item.amount, 1),
                        eur: await this.formatFromDollar(item.amount, 2),
                    };
                } else if (item.currency === 2) {
                    // USD
                    item.currencyValue = {
                        aed: await this.formatFromEuro(item.amount, 3),
                        usd: await this.formatFromEuro(item.amount, 1),
                        eur: await this.formatFromEuro(item.amount, 2),
                    };
                }
            } else {
                item.currencyValue = {
                    aed: await this.formatFromAed(item.amount, 3),
                    usd: await this.formatFromAed(item.amount, 1),
                    eur: await this.formatFromAed(item.amount, 2),
                };
            }

            item.showFull = false;
        }

        return items;
    }

    async formatCurrencies(items: any[]) {
        for (const item of items) {
            if (item.currency) {
                if (item.currency === 0) {
                    // AED
                    item.currencyValue = {
                        aed: await this.formatFromAed(item.value, 3),
                        usd: await this.formatFromAed(item.value, 1),
                        eur: await this.formatFromAed(item.value, 2),
                    };
                } else if (item.currency === 1) {
                    // USD
                    item.currencyValue = {
                        aed: await this.formatFromDollar(item.value, 3),
                        usd: await this.formatFromDollar(item.value, 1),
                        eur: await this.formatFromDollar(item.value, 2),
                    };
                } else if (item.currency === 2) {
                    // USD
                    item.currencyValue = {
                        aed: await this.formatFromEuro(item.value, 3),
                        usd: await this.formatFromEuro(item.value, 1),
                        eur: await this.formatFromEuro(item.value, 2),
                    };
                }
            } else {
                item.currencyValue = {
                    aed: await this.formatFromAed(item.value, 3),
                    usd: await this.formatFromAed(item.value, 1),
                    eur: await this.formatFromAed(item.value, 2),
                };
            }

            item.showFull = false;
        }

        return items;
    }

    async formatFromAed(value: number, type: number) {
        let formatter;
        let currency = 0;
        if (type === 1) {
            formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            });

            currency = value * this.conversion_rates.aed.usd;
        } else if (type === 2) {
            formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2
            });

            currency = value * this.conversion_rates.aed.eur;
        }
        else if (type === 3) {
            formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'AED',
                minimumFractionDigits: 2
            });

            currency = value;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return formatter.format(currency);
    }

    async formatFromDollar(value: number, type: number) {
        let formatter;
        let currency = 0;
        if (type === 1) {
            formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            });

            currency = value;
        } else if (type === 2) {
            formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2
            });

            currency = value * this.conversion_rates.usd.eur;
        }
        else if (type === 3) {
            formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'AED',
                minimumFractionDigits: 2
            });

            currency = value * this.conversion_rates.usd.aed;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return formatter.format(currency);
    }

    async formatFromEuro(value: number, type: number) {
        let formatter;
        let currency = 0;
        if (type === 1) {
            formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            });

            currency = value * this.conversion_rates.eur.usd
        } else if (type === 2) {
            formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2
            });

            currency = value;
        }
        else if (type === 3) {
            formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'AED',
                minimumFractionDigits: 2
            });

            currency = value * this.conversion_rates.eur.aed;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return formatter.format(currency);
    }
}
