<template>
<div>
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Create business
    </h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">
      Business information
    </p>
  </div>
  <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
    <dl class="sm:divide-y sm:divide-gray-200">
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Name of business
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Company name">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Legal name
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.legalName" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Legal name">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Business ID No
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.businessIdNo" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Business ID No">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Tax registration number
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.taxRegistrationNumber" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Tax registration number">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Tax form
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.taxForm" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Tax form">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Industry
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.industry" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Industry">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Company email
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.companyEmail" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Company email">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Company phone
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.companyPhoneNr" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Company phone">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Company address
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input type="text" v-model="business.companyAddress" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-500 rounded-md" placeholder="Company address">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Public
          <div class="block">
            <span class="text-sm text-gray-500">Receipts are public</span>
          </div>
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input v-model="isPublic" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-500 rounded">
          </div>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="font-medium text-gray-800">
          Private
          <div class="block">
            <span class="text-sm text-gray-500">Receipts won't be public</span>
          </div>
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div>
            <input v-model="isPrivate" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-500 rounded">
          </div>
        </dd>
      </div>
      <div class="sm:py-5 text-center sm:px-6">
        <button v-if="!loading && business.name !== ''" v-on:click="createBusiness" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Create Business
        </button>
        <button v-else-if="!loading && business.name === ''" class="inline-flex cursor-not-allowed items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Create Business
        </button>
        <button v-else-if="loading" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Create Business
        </button>
      </div>
    </dl>
  </div>
</div>
</template>

<script>
export default {
  name: "AddCompany",
  props: {
    loading: Boolean
  },
  data: () => ({
    isPublic: true,
    isPrivate: false,
    business: {
      name: '',
      legalName: null,
      businessIdNo: null,
      taxRegistrationNumber: null,
      taxForm: null,
      industry: null,
      companyEmail: null,
      companyPhoneNr: null,
      companyAddress: null,
      isPrivate: false,
      usesFourEyesPrinciple: false
    },
  }),
  methods: {
    createBusiness: function() {
      const dto = this.business;

      this.business = {
        name: '',
        legalName: null,
        businessIdNo: null,
        taxRegistrationNumber: null,
        taxForm: null,
        industry: null,
        companyEmail: null,
        companyPhoneNr: null,
        companyAddress: null,
        isPrivate: false,
        usesFourEyesPrinciple: false
      }
      this.$emit('createBusiness', dto);
    }
  },
  watch: {
    isPublic: {
      handler: function() {
        if (this.isPublic) {
          this.isPrivate = false;
          this.business.isPrivate = false;
        }
      },
      deep: true
    },
    isPrivate: {
      handler: function() {
        if (this.isPrivate) {
          this.isPublic = false;
          this.business.isPrivate = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
