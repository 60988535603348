import axios from "axios";
import store from "@/store";

export default class HashService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getters.bearerToken}`,
        }
    }

    static async get(id: number): Promise<never> {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/TxHash/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async add(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/TxHash/add`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async allCompany(companyId: number){
        return await axios.get(`${process.env.VUE_APP_SERVICE}/TxHash/get-all?companyId=${companyId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async allSuggestions(companyId: number, amount: number, currency: number, date: string, receiptId:number)
    {
        if(receiptId !== null)
        {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/TxHash/get-all-suggestions?companyId=${companyId}&amount=${amount}&currency=${currency}&dateTime=${date}&receiptId=${receiptId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }else{
            return await axios.get(`${process.env.VUE_APP_SERVICE}/TxHash/get-all-suggestions?companyId=${companyId}&amount=${amount}&currency=${currency}&dateTime=${date}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
        
    }    

    async all(companyId: number, amount: number, currency: number, date: string) {
        if (companyId && companyId !== 0) {
            if (amount !== 0 && date !== '' && currency !== null) {
                return await axios.get(`${process.env.VUE_APP_SERVICE}/TxHash/receipt-suggestion?companyId=${companyId}&amount=${amount}&currency=${currency}&dateTime=${date}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${store.getters.bearerToken}`,
                    },
                });
            }
            return await axios.get(`${process.env.VUE_APP_SERVICE}/TxHash/get-all?companyId=${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        } else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/TxHash/get-all`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
    }

    async edit(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/TxHash/edit`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async delete(id :number) {
        return await axios.delete(`${process.env.VUE_APP_SERVICE}/TxHash/delete/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }
}
