<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-cols-4">
    <div class="space-y-6 lg:col-span-3">
      <div>
        <SkeletonAccount v-if="!loaded" class="block border bg-white rounded-2xl p-4" />
        <Account v-if="tabs.account && loaded"
                 @getUserProfile="getProfile"
                 :profile="profile"
                 :loading="loading"
                 class="block border bg-white rounded-2xl p-4"/>

<!--        <AccessTokens class="block border bg-white rounded-2xl p-4"-->
<!--                      :accessTokens="accessTokens"-->
<!--                      :loading="loading"-->
<!--                      @deactivateToken="deactivateToken"-->
<!--                      v-if="tabs.tokens && accessTokens.length > 0" />-->
        <AddCompany v-if="tabs.company"
                    :loading="loading"
                    class="block border bg-white rounded-2xl p-4"/>

        <div v-if="tabs.permissions" class="block border bg-white rounded-2xl p-4">
          <div>
            <h2 class="text-3xl font-bold mt-2">
              Your permissions
            </h2>
            <p class="w-2/3 py-2">
              Overview of permissions you have on companies
            </p>
            <div>
              <span class="text-sm" v-if="permissions.length === 0">
                You don't have any permissions on other companies
              </span>
              <div v-else>
                <div class="grid grid-cols-2 gap-2">
                  <div>
                    <strong>Companies</strong>
                  </div>
                  <div>
                    <strong>Permissions</strong>
                  </div>
                </div>
                <div v-for="(permission, key) in permissions" :key="key" class="grid grid-cols-2 gap-2 mb-2">
                  <div>
                    {{permission.companyName}}
                  </div>
                  <div>
                    <span v-if="permission.permissionType === 0">
                      Read Only
                    </span>
                    <span v-if="permission.permissionType === 1">
                      Edit
                    </span>
                    <span v-if="permission.permissionType === 2">
                      Admin
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <h2 class="text-3xl font-bold mt-2">
              Granted permissions
            </h2>
            <p class="w-2/3 py-2">
              Overview of granted permissions users have on your companies
            </p>
            <div>
              <span class="text-sm" v-if="grantedPermissions.length === 0">
                No permissions given to users
              </span>
              <div v-else>
                <div class="grid grid-cols-4 gap-2">
                  <div>
                    <strong>User</strong>
                  </div>
                  <div>
                    <strong>Companies</strong>
                  </div>
                  <div>
                    <strong>Permissions</strong>
                  </div>
                  <div>
                    <strong>
                      Actions
                    </strong>
                  </div>
                </div>
                <div v-for="(permission, key) in grantedPermissions" :key="key" class="grid grid-cols-4 gap-2 mb-2">
                  <div class="truncate">
                    {{hideEmail(permission.emailAddress)}}
                  </div>
                  <div>
                    <div class="block truncate" v-for="(company, i) in permission.permissionDetails" :key="i">
                      {{company.companyName}}
                    </div>
                  </div>
                  <div>
                    <div class="block" v-for="(company, i) in permission.permissionDetails" :key="i">
                      <span v-if="company.permissionType === 0">
                        Read Only
                      </span>
                      <span v-if="company.permissionType === 1">
                        Edit
                      </span>
                      <span v-if="company.permissionType === 2">
                        Admin
                      </span>
                    </div>

                  </div>
                  <div>
                    <div class="block" v-for="(company, i) in permission.permissionDetails" :key="i">
                      <div class="flex">
                        <span class="text-blue-500 cursor-pointer" v-on:click="editUserPermission(permission, company.companyId)">
                          Edit
                        </span>
                        <span class="text-red-500 cursor-pointer pl-3" v-on:click="deleteUserPermission(permission, company.companyId)">
                          Delete
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!showAddUserPermission" class="mt-8 text-center">
            <button class="px-3 py-2 bg-blue-500 rounded-md text-white hover:bg-blue-600 text-sm"
                    v-on:click="toggleShowAddUserPermission">
              <span>Add user permission</span>
            </button>
          </div>

          <div v-if="showAddUserPermission || showEditUserPermission" class="mt-4">
            <h2 v-if="showAddUserPermission" class="text-3xl font-bold mt-2">
              Add permissions
            </h2>
            <h2 v-else-if="showEditUserPermission" class="text-3xl font-bold mt-2">
              Edit permissions
            </h2>
            <p class="w-2/3 py-2">
              Grant users access to your companies with different user roles.
            </p>
            <div>
              <div>
                <label>Email address of user to grant access</label>
                <input v-model="requestPermission.userAccountEmail"
                       type="text"
                       required
                       placeholder="Email address"
                       class="block w-1/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
              </div>
              <div class="mt-2">
                <label>Permission</label>
                <select required v-model="requestPermission.permissionType" class="w-1/3 block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                  <option :value="0">Read only</option>
                  <option :value="1">Edit</option>
                  <option :value="2">Admin</option>
                </select>
                <div v-if="requestPermission.permissionType === 2" class="block mt-2">
                  <div class="px-2 py-2 bg-blue-100 text-blue-500 rounded-md">
                    When Admin permission type is selected, you grant access to all your companies.
                  </div>
                </div>
              </div>
              <div v-if="requestPermission.permissionType !== 2" class="mt-2">
                <label>Select company to grant access to</label>
                <select required v-model="requestPermission.companyId" class="w-1/3 block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                  <option selected disabled :value="null">Select company</option>
                  <option v-for="(company, key) in accountCompanies" :key="key" :value="company.id">{{company.name}}</option>
                </select>
              </div>
              <div class="mt-2">
                <button v-if="!loading &&
                showAddUserPermission &&
                !showEditUserPermission &&
                (requestPermission.companyId === null ||
                requestPermission.userAccountEmail === '')"
                       class="px-3 py-2 border cursor-not-allowed opacity-50 border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Add permission
                </button>
                <button v-else-if="!loading &&
                showAddUserPermission &&
                !showEditUserPermission &&
                requestPermission.companyId !== null &&
                requestPermission.userAccountEmail !== ''"
                        v-on:click="addPermission" class="px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Add permission
                </button>
                <button v-else-if="loading && showAddUserPermission && !showEditUserPermission" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Add permission
                </button>
                <button v-else-if="!loading && !showAddUserPermission && showEditUserPermission" v-on:click="editPermission" class="px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Edit permission
                </button>
                <button v-else-if="loading && !showAddUserPermission && showEditUserPermission" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Edit permission
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section aria-labelledby="timeline-title" class="lg:col-start-4 lg:col-span-1">
      <div class="border rounded-2xl p-4 bg-white">
        <div
            v-on:click="toggleTab(true, false,false, false, false)"
            class="block border cursor-pointer hover:bg-gray-100 rounded-lg p-2"
            v-bind:class="{'text-white bg-blue-500 hover:bg-blue-500': tabs.account}">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" v-bind:class="{'text-white': tabs.account}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            <span class="pl-4" v-bind:class="{'text-white': tabs.account}">Account</span>
          </div>
        </div>
<!--        <div v-on:click="toggleTab(false,true, false, false, false)"-->
<!--             class="block border mt-2 cursor-pointer hover:bg-gray-100 rounded-lg p-2"-->
<!--             v-bind:class="{'text-white bg-blue-500 hover:bg-blue-500': tabs.company}">-->
<!--          <div class="flex">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />-->
<!--            </svg>-->
<!--            <span class="pl-4" v-bind:class="{'text-white': tabs.company}">Add Company</span>-->
<!--          </div>-->
<!--        </div>-->
        <div v-on:click="toggleTab(false,false, false, false, true)"
             class="block border mt-2 cursor-pointer hover:bg-gray-100 rounded-lg p-2"
             v-bind:class="{'text-white bg-blue-500 hover:bg-blue-500': tabs.permissions}">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" v-bind:class="{'text-white': tabs.components}" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span class="pl-4" v-bind:class="{'text-white': tabs.permissions}">Permissions</span>
          </div>
        </div>
<!--        <div v-on:click="toggleTab(false, false, true)"-->
<!--             class="block border mt-2 cursor-pointer hover:bg-gray-100 rounded-lg p-2"-->
<!--             v-bind:class="{'text-white bg-blue-500 hover:bg-blue-500': tabs.components}">-->
<!--          <div class="flex">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" v-bind:class="{'text-white': tabs.components}" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />-->
<!--              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />-->
<!--            </svg>-->
<!--            <span class="pl-4" v-bind:class="{'text-white': tabs.components}">Components</span>-->
<!--          </div>-->
<!--        </div>-->
        <span v-on:click="logout" class="block mt-8 border bg-gray-200 cursor-pointer hover:bg-gray-300 rounded-lg p-2">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <span class="text-gray-900 pl-4">Sign out</span>
          </div>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import AccountService from "@/service/account";
import Account from "@/components/profile/tabs/Account";
import SkeletonAccount from '@/components/skeletons/Profile';
import BusinessService from "@/service/business";
import AddCompany from "@/components/profile/tabs/AddCompany";

export default {
  name: "Profile",
  components: {
    AddCompany,
    Account,
    // AccessTokens,
    SkeletonAccount
  },
  data: () => ({
    accountService: null,
    profile: {},
    clients: [],
    expenses: [],
    accountCompanies: [],
    showAddUserPermission: true,
    showEditUserPermission: false,
    requestPermission: {
      companyId: null,
      userAccountEmail: '',
      permissionType: 0,
    },
    deletePermission: {
      companyId: null,
      permissionType: 0,
    },
    tabs: {
      account: true,
      company: false,
      tokens: false,
      components: false,
      permissions: false,
    },
    loaded: false,
    loading: false,
    permissions: [],
    grantedPermissions: [],
    currency: 'USD',
  }),
  beforeMount: async function() {
    this.accountService = new AccountService();
    await this.getProfile();
    await this.getPermissions();
    await this.getGrantedPermissions();
    await this.getAccountCompanies();
    this.loaded = true;
  },
  methods: {
    getProfile: async function() {
      this.loaded = false;

      await this.accountService.getProfile()
        .then((response) => {
          this.profile = response.data;
        }).catch((e) => {
          console.log(`there is an error`, e);
        });
    },
    getPermissions: async function() {
      await this.accountService.getPermissions()
        .then((response) => {
          this.permissions = response.data;
        }).catch((e) => {
          console.log(`there is an error`, e);
        }).finally(() => {
          this.loaded = true;
        });
    },
    getGrantedPermissions: async function() {
      await this.accountService.getGrantedPermissions()
        .then((response) => {
          this.grantedPermissions = response.data;
        }).catch((e) => {
          console.log(`there is an error`, e);
        }).finally(() => {
          this.loaded = true;
        });
    },
    addPermission: async function() {
      this.loading = !this.loading;
      const service = new AccountService();
      const dto = this.requestPermission;
      await service.grantPermission(dto)
          .then(() => {
            this.$notifications(
                `Permission Granted`,
                `${this.requestPermission.userAccountEmail} granted access`,
                '',
                0,
                true
            );
          }).catch((e) => {
            if (e.request && e.request.response) {
              const error = JSON.parse(e.request.response);
              this.$notifications(
                  'Something Went Wrong Granting Permissions',
                  error.message,
                  '',
                  1,
                  true
              );
            }
          }).finally(() => {
            this.requestPermission = {
              companyId: null,
              userAccountEmail: '',
              permissionType: 0,
            };
            this.loading = !this.loading;
            this.getPermissions();
            this.getGrantedPermissions();
          });
    },
    editPermission: async function() {
      this.loading = !this.loading;
      const service = new AccountService();
      const dto = {
        userAccountId: this.requestPermission.userAccountId,
        companyId: this.requestPermission.companyId,
        permissionType: this.requestPermission.permissionType
      }
      await service.editPermission(dto)
          .then(() => {
            this.$notifications(
                `Permission Granted`,
                `${this.requestPermission.userAccountEmail} granted permission is updated`,
                '',
                0,
                true
            );
          }).catch((e) => {
            if (e.request && e.request.response) {
              const error = JSON.parse(e.request.response);
              this.$notifications(
                  'Something Went Wrong Granting Permissions',
                  error.message,
                  '',
                  1,
                  true
              );
            }
          }).finally(() => {
            this.showEditUserPermission = !this.showEditUserPermission;
            this.requestPermission = {
              companyId: null,
              userAccountEmail: '',
              permissionType: 0,
            };
            this.loading = !this.loading;
            this.getPermissions();
            this.getGrantedPermissions();
          });
    },
    getAccountCompanies: async function() {
      const service = new BusinessService();
      await service.getAccountAdminCompanies()
        .then((response) => {
          this.accountCompanies = response.data.items;
        }).catch((e) => {
          console.log(e.request)
          console.log(`there is an error`, e);
        });
    },
    createBusiness: async function(dto) {
      const service = new BusinessService();
      await service.createBusiness(dto)
        .then(() => {
          this.$notifications(
              `Company Created`,
              `The company ${dto.name} is successfully created`,
              '',
              0,
              true
          );
          this.$router.push({name: 'Business'});
        }).catch((e) => {
          if (e.request && e.request.response) {
            const error = JSON.parse(e.request.response);
            this.$notifications(
                'Something Went Wrong Creating The Business.',
                `Error message: ${error.message}`,
                '',
                1,
                true
            );
          }
        });
    },
    editUserPermission: function(permission, companyId) {
      this.showEditUserPermission = !this.showEditUserPermission;

      if (this.showEditUserPermission) {
        this.showAddUserPermission = false;
      } else {
        this.showAddUserPermission = true;
      }
      permission.permissionDetails.forEach(per => {
        if (Number(per.companyId) === Number(companyId)) {
          this.requestPermission = {
            companyId: per.companyId,
            userAccountEmail: permission.emailAddress,
            userAccountId: permission.userAccountId,
            permissionType: per.permissionType,
          };
        }
      });
    },
    deleteUserPermission: async function(permission, companyId) {
      permission.permissionDetails.forEach(per => {
        if (Number(per.companyId) === Number(companyId)) {
          this.deletePermission = {
            companyId: per.companyId,
            userAccountId: permission.userAccountId,
          };
        }
      });

      const service = new AccountService();
      const dto = this.deletePermission;
      await service.deletePermission(dto)
          .then(() => {
            this.$notifications(
                `Permission Deleted`,
                `Permission is removed from user`,
                '',
                0,
                true
            );
          }).catch((e) => {
            this.loading = !this.loading;
            if (e.request && e.request.response) {
              const error = JSON.parse(e.request.response);
              this.$notifications(
                  'Something Went Wrong Deleting Permissions',
                  `${error.message}`,
                  '',
                  1,
                  true
              );
            }
          }).finally(() => {
            this.getPermissions();
            this.getGrantedPermissions();
          });
    },
    toggleTab: function(account, company, tokens, components, permissions) {
      this.tabs = {
        account: account,
        company: company,
        tokens: tokens,
        components: components,
        permissions: permissions
      }
    },
    toggleShowAddUserPermission: function() {
      this.showAddUserPermission = !this.showAddUserPermission;
      this.showEditUserPermission = false;
    },
    hideEmail: function(email) {
      const splitted = email.split("@");
      let part1 = splitted[0];
      const avg = part1.length / 2;
      part1 = part1.substring(0, (part1.length - avg));
      const part2 = splitted[1];
      return part1 + "...@" + part2;
    },
    logout: function() {
      this.$root.$emit('SetAuthentication', false);
      this.$root.$emit('LogoutUser');
    }
  },
  watch: {
    requestPermission: {
      handler: function() {
        if (this.requestPermission.permissionType === 2) {
          this.requestPermission.companyId = this.accountCompanies[0].id;
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
