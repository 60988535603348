<template>
<div>
  <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
    <div class="bg-white overflow-hidden shadow-lg rounded-lg">
      <div class="p-5">
        <div class="flex items-center">          
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          <div class=" ml-5 w-0 flex-1">
            <dl>
              <dt class="text-sm font-medium text-gray-500">
                Total Funds
              </dt>
              <dd>
                <div class="text-lg font-medium text-gray-900">
                  {{stats.funds}}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>   

    <div class="bg-white overflow-hidden shadow-lg rounded-lg">
      <div class="p-5">
        <div class="flex items-center">          
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          <div class=" ml-5 w-0 flex-1">
            <dl>
              <dt class="text-sm font-medium text-gray-500">
                Budget Left
              </dt>
              <dd>
                <div class="text-lg font-medium text-gray-900">
                  {{stats.budgetLeft}}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>   
  </div>
</div>
</template>

<script>
import StatsService from "@/service/stats";
import store from '@/store';
import FormatHelper from "@/helpers/format";
import * as Url from "url";

export default {
  name: "TxHashStats",
  props: {
    
  },
  data: () => ({
    authenticated: store.getters.authenticated,    
    stats: {      
      funds: 0,
      budgetLeft: 0      
    },
    accessToken: '',
    copied: false,
    currencyFormat: 1,
    companyId: null,
  }),
  beforeMount: async function() {
    await this.getTxHashStats();

    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id;
    }

    // format to dollar
    await this.formatFunds(this.currencyFormat);

    this.$root.$off('setTxHashCurrency');
    this.$root.$on('setTxHashCurrency', async (value) => {    
        await this.getTxHashStats();
      if (value === 'AED') {
        this.currencyFormat = 3;
      } else if (value === 'EUR') {
        this.currencyFormat = 2;
      } else if (value === 'USD') {
        this.currencyFormat = 1;
      }
      await this.formatFunds(this.currencyFormat)
    });

    this.$root.$off('setCompanyId');
    this.$root.$on('setCompanyId', async (value) => {    
        this.companyId = value;
        await this.getTxHashStats();
        await this.formatFunds(this.currencyFormat);      
    });
  },
  methods: {
    getTxHashStats: async function() {
      const service = new StatsService();
      await service.getTxHashStats(this.companyId)
        .then((response) => {

          this.stats = {
            funds: response.data.totalFunds,
            budgetLeft: response.data.budgetLeft
          }
        }).catch((e) => {
          console.log(e.request)
        });
    },
    formatFunds: async function(type) {
        const helper = new FormatHelper();

        if (type === 3) {
          this.stats.funds = await helper.formatFromDollar(this.stats.funds, 3);
          this.stats.budgetLeft = await helper.formatFromDollar(this.stats.budgetLeft, 3);
        } else if (type === 2) {
          this.stats.funds = await helper.formatFromDollar(this.stats.funds, 2);
          this.stats.budgetLeft = await helper.formatFromDollar(this.stats.budgetLeft, 2);
        } else if (type === 1) {
          this.stats.funds = await helper.formatFromDollar(this.stats.funds, 1);
          this.stats.budgetLeft = await helper.formatFromDollar(this.stats.budgetLeft, 1);
        }
    }    
  }
}
</script>

<style scoped>

</style>