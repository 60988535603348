<template>
  <div class="home">
    <Search v-if="!authenticated" />

    <div class="max-w-screen-2xl mx-auto px-4 sm:px-6 relative mt-6">
      <SkeletonCards v-if="!loaded" />
      <Stats v-if="loaded" />
    </div>

    <div class="max-w-screen-2xl  mx-auto px-4 sm:px-6 relative mt-6">
      <div v-if="receipts.length > 0 || companies.length > 0 && loaded">
        <div class="pb-4 nlntBo">
          <div class="flex">
            <div class="flex-1">
              <h3 v-if="togglePage" class="text-2xl font-bold">Latest Receipts</h3>
              <h3 v-if="!togglePage" class="text-2xl font-bold">Registered Business</h3>
            </div>
            <div>
              <select v-on:change="togglePage = !togglePage" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                <option :selected="togglePage" :value="togglePage">Receipts</option>
                <option :selected="!togglePage" :value="!togglePage">Business</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <SkeletonTable v-if="!loaded" />
      <div id="receipt" v-if="receipts.length > 0 || companies.length > 0">
        <Receipts
            class="hidden md:block"
            :receiptsArray="receipts"
            :pagination="pagination"
            :isLoadingNext="isLoadingNext"
            :isLoadingPrevious="isLoadingPrevious"
            :conversion_rates="conversion_rates"
            @getReceipts="getReceipts"
            v-if="togglePage && loaded" />
        <ReceiptsMobile
            class="md:hidden"
            :receiptsArray="receipts"
            :pagination="pagination"
            :isLoadingNext="isLoadingNext"
            :isLoadingPrevious="isLoadingPrevious"
            :conversion_rates="conversion_rates"
            @getReceipts="getReceipts"
            v-if="togglePage && loaded" />

        <Businesses
            class="hidden md:block"
            :companyArray="companies"
            :pagination="compPagination"
            :isLoadingNext="isLoadingNext"
            :isLoadingPrevious="isLoadingPrevious"
            @getCompanies="getCompanies"
            v-if="!togglePage && loaded"/>
        <BusinessesMobile
            class="md:hidden"
            :companyArray="companies"
            :pagination="compPagination"
            :isLoadingNext="isLoadingNext"
            :isLoadingPrevious="isLoadingPrevious"
            @getCompanies="getCompanies"
            v-if="!togglePage && loaded" />
      </div>
      <div v-else-if="loaded">
        <div v-if="receipts.length < 1">
          <div class="text-center">
            <h3 class="mt-2 text-sm font-medium text-gray-900">No receipts or businesses registered</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/search/SearchBanner.vue';
import Stats from '@/components/cards/Stats.vue';
import Receipts from '@/components/tables/Receipt.vue';
import ReceiptsMobile from '@/components/tables/mobile/Receipt.vue';
import BusinessesMobile from '@/components/tables/mobile/Business.vue';
import Businesses from '@/components/tables/Business.vue';
import SkeletonTable from '@/components/skeletons/Table';
import SkeletonCards from '@/components/skeletons/Cards';
import store from "@/store";
import ReceiptService from "@/service/receipt";
import BusinessService from "@/service/business";
import FormatHelper from "@/helpers/format";

export default {
  name: "Home",
  components: {
    Search,
    Stats,
    Receipts,
    ReceiptsMobile,
    BusinessesMobile,
    SkeletonTable,
    SkeletonCards,
    Businesses
  },
  data: () => ({
    authenticated: store.getters.authenticated,
    togglePage: false,
    receipts: [],
    companies: [],
    page: 1,
    pageSize: 100,
    pagination: {
      show: 1,
      to: 100,
      totalItems: 0,
      pages: [],
      totalPages: 1,
    },
    compPage: 1,
    compPageSize: 100,
    compPagination: {
      show: 1,
      to: 100,
      totalItems: 0,
      pages: [],
      totalPages: 1,
    },
    loaded: false,
    isLoadingNext: false,
    isLoadingPrevious: false,
    conversion_rates: {
      eur: store.getters.conversion.eur,
      usd: store.getters.conversion.usd
    },
  }),
  created: async function() {
    // if (this.$route.query.type && this.$route.query.accessToken) {
    //   if (this.$route.query.type === 'receipt') {
    //     await this.redirectReceipt();
    //   } else {
    //     await this.redirectBusiness();
    //   }
    // } else {
    const bearerToken = localStorage.getItem('accountant_bearer');
    if (bearerToken && this.$route.name !== 'Receipt')
      await this.$router.push({name: 'Receipt'});
  // }

    this.$root.$on('FilterReceipts', async (type) => {
      await this.filter(type);
    });
  },
  beforeMount: async function() {
    await this.getReceipts(this.page, this.pageSize, false, false);
    await this.getCompanies(this.compPage, this.compPageSize, false, false);
    await this.setPage();

    this.loaded = true;
  },
  methods: {
    filter: async function(type) {
      // Filter is turned off
      if (type === null) {
        await this.getReceipts(this.page, this.pageSize, false, false);
        this.filterType = null;
      } else {
        // When there is no filter yet, copy list to allReceipts
        if (this.filterType === null) {
          this.allReceipts = this.receipts;
        }

        // Filter is changing
        if (this.filterType !== type) {
          this.receipts = this.allReceipts
        }
        if (type === 0) {
          const service = new ReceiptService();
          await service.getAllExpensesForAccount(this.page, this.pageSize)
              .then((response) => {
                this.setCurrencyValues(response.data.items);
                this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
              }).catch((e) => {
                console.log(e.request)
                console.log(`there is an error`, e);
              })
        }
        else if (type === 1) {
          const service = new ReceiptService();
          await service.getAllAssetsForAccount(this.page, this.pageSize)
              .then((response) => {
                this.setCurrencyValues(response.data.items);
                this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
              }).catch((e) => {
                console.log(e.request)
                console.log(`there is an error`, e);
              })
        }
        this.filterType = type;
      }
    },
    getReceipts: async function(page, pageSize, loadingNext, loadingPrevious) {
      if (loadingNext) {
        this.isLoadingNext = !this.isLoadingNext;
      } else if (loadingPrevious) {
        this.isLoadingPrevious = !this.isLoadingPrevious;
      }
      const service = new ReceiptService();
      await service.getAllReceipts(page, pageSize)
        .then((response) => {
          this.setCurrencyValues(response.data.items);
          this.setPagination(response.data.page, response.data.pageSize, response.data.totalItems);
        }).catch((e) => {
          console.log(e);
        }).finally(() => {
          if (loadingNext) {
            this.isLoadingNext = !this.isLoadingNext;
          } else if (loadingPrevious) {
            this.isLoadingPrevious = !this.isLoadingPrevious;
          }
        });
    },
    getCompanies: async function(page, pageSize, loadingNext, loadingPrevious) {
      if (loadingNext) {
        this.isLoadingNext = !this.isLoadingNext;
      } else if (loadingPrevious) {
        this.isLoadingPrevious = !this.isLoadingPrevious;
      }
      const service = new BusinessService();
      await service.getAllCompanies(page, pageSize)
        .then((response) => {
          const companies = response.data.items;

          for (const company of companies) {
            company.showFull = false;
          }
          this.companies = companies;

          this.setCompanyPagination(response.data.page, response.data.pageSize, response.data.totalItems);
        }).catch((e) => {
          console.log(e);
        }).finally(() => {
          if (loadingNext) {
            this.isLoadingNext = !this.isLoadingNext;
          } else if (loadingPrevious) {
            this.isLoadingPrevious = !this.isLoadingPrevious;
          }
        });
    },
    setPage: async function() {
      // When there are no receipts but companies added, show companies instead of receipts.
      if (this.receipts.length < 1 && this.companies.length > 0) {
        this.togglePage = false;
      }
    },
    setCurrencyValues: async function(receipts) {
      this.receipts = [];
      const helper = new FormatHelper();
      this.receipts = await helper.formatCurrencies(receipts);
    },
    setPagination: function(page, pageSize, totalItems) {
      this.page = page;
      this.pageSize = pageSize;
      this.pagination.totalItems = totalItems;

      const pages = Math.ceil(this.pagination.totalItems / this.pageSize);
      this.pagination.pages = [];
      for (let i = 0; i < pages; i++) {
        this.pagination.pages.push(i+1);
      }

      this.pagination.totalPages = pages;
    },
    setCompanyPagination: function(page, pageSize, totalItems) {
      this.compPage = page;
      this.compPageSize = pageSize;
      this.compPagination.totalItems = totalItems;

      const pages = Math.ceil(this.compPagination.totalItems / this.compPageSize);
      this.compPagination.pages = [];
      for (let i = 0; i < pages; i++) {
        this.compPagination.pages.push(i+1);
      }

      this.compPagination.totalPages = pages;
    }
  }
}
</script>
