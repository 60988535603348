<template>
<div>
  <div class="nbgBanner banner bg-gradient-to-r from-blue-500 to-purple-500 w-full relative h-40 pt-2">
    <div class="max-w-screen-2xl mx-auto  relative">
      
      <div class="absolute z-1 top-3 w-full px-4 sm:px-6">
        <h3 class="text-white text-3xl font-semibold">
          Accountant Explorer
        </h3>
        <div class="w-full pr-8 md:w-1/2 mt-4">
          <div>
            <div class="mt-1 relative">
              <input v-on:input="search" v-model="searchQuery" type="text" required class="bg-white mt-3 py-2 px-4 text-gray-800 rounded-tl-md rounded-bl-md w-full block rounded-lg border-none focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="Search by Company / Receipt Hash / Vendor / Txn Hash">
              <div class="absolute inset-y-0 right-0 flex">
                <kbd class="inline-flex bg-blue-600 items-center rounded-tr-md rounded-br-md px-2 border-none focus:outline-none text-sm font-sans font-medium text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </kbd>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="searchQuery.length > 2" class="block shadow-sm relative top-36 px-2 py-2 border rounded-lg z-10 mt-2 w-full bg-white">
        <div>
          <div class="flex">
            <div class="flex-1">
              <h3 class="text-lg font-semibold">
                Search results:
              </h3>
            </div>
            <div v-if="searchResults.length > 0">
              <span v-on:click="searchQuery = ''" class="text-blue-500">empty results</span>
            </div>
          </div>
        </div>
        <div v-if="searchResults.length > 0" class="mt-2">
          <div v-for="(item, key) in searchResults" :key="key" class="py-4 border-t">
            <div v-if="item.type === 0" v-on:click="goToUrl(item.id, 0, formatName(item.name))" class="cursor-pointer hover:text-purple-500">
              {{ item.name }}
            </div>
            <div v-else-if="item.type === 1">
              <div v-on:click="goToUrl(item.id, 1, formatName(item.companyName))"  class="cursor-pointer hover:text-purple-500">
                <span v-if="item.companyName">{{ item.companyName }}</span>
                <span v-if="item.vendor" class="pl-1">paid {{ item.vendor }}</span>
              </div>
              <div v-if="item.chainType !== null && item.transactionHash !== null" class="mt-1 truncate">
                <span v-if="item.chainType === 0">
                  <a class="text-blue-500 truncate hover:text-blue-600 text-sm"
                     :href="`https://blockchain.com/btc/tx/${item.transactionHash}`"
                     target="_blank">{{ item.transactionHash }}</a>
                </span>
                <span v-if="item.chainType === 1">
                  <a class="text-blue-500 truncate hover:text-blue-600 text-sm"
                     :href="`https://etherscan.io/tx/${item.transactionHash}`"
                     target="_blank">{{ item.transactionHash }}</a>
                </span>
                <span v-if="item.chainType  === 2">
                  <a class="text-blue-500 truncate hover:text-blue-600 text-sm"
                     :href="`https://bscscan.com/tx/${item.transactionHash}`"
                     target="_blank">{{ item.transactionHash }}</a>
                </span>
                <span v-if="item.chainType === 3">
                  <a class="text-blue-500 hover:text-blue-600 text-sm"
                      :href="`https://tronscan.org/#/transaction/${item.transactionHash}`"
                      target="_blank">{{ item.transactionHash }}</a>
                </span>
                <span v-if="item.chainType === 4">
                  <a class="text-blue-500 hover:text-blue-600 text-sm"
                      :href="`https://solscan.io/tx/${item.transactionHash}`"
                      target="_blank">{{ item.transactionHash }}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-2">
          No results found.
          <div class="block text-center">
            <span v-on:click="searchQuery = ''" class="text-blue-500">empty results</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SearchService from "@/service/search";

export default {
  name: "SearchBanner",
  data: () => ({
    searchResults: [],
    searchQuery: '',
    service: null,
  }),
  beforeMount: function() {
    this.service = new SearchService();
  },
  methods: {
    search: async function() {
      if (this.searchQuery.length > 2) {
        this.service.getSearchResults(this.searchQuery)
          .then((response) => {
            this.searchResults = response.data.items;
          }).catch((e) => {
            console.log(e.request);
        })
      }
    },
    goToUrl: function(id, type, compName) {
      this.searchQuery = '';
      if (type === 0) {
        this.$router.push({name: 'BusinessPublicShow', params: {id: id, name: compName}});
      } else if (type === 1) {
        this.$router.push({name: 'ReceiptPublicShow', params: {id: id}});
      }
    },
    formatDollar: function(value) {
      let formatter;
      let currency = 0;
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });

      currency = value;

      if (currency !== 0) {
        return formatter.format(currency);
      }
    },
    formatName: function(name) {
      for (let i = 0; i < name.length; i++) {
        name = name.replace(" ", "-");
      }
      return name.toLowerCase();
    }
  }
}
</script>

<style scoped>

</style>
