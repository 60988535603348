<template>
  <div>
    <div class="w-full mt-4 md:ml-0">
      <label :for="search" class="sr-only">Search</label>
      <div class="relative w-full text-gray-400 focus-within:text-gray-600">
        <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none" aria-hidden="true">
          <!-- Heroicon name: solid/search -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
        <input v-on:input="search" v-model="searchQuery" class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search transactions" type="search">
      </div>
      <div v-if="searchQuery.length > 2" class="w-full z-10 relative border rounded-md bg-white px-2 py-3 mt-4 md:ml-0">
        <div class="w-full md:ml-0">
          <div v-if="searchResults.length > 0" class="relative w-full text-gray-400 focus-within:text-gray-600">
            <div v-for="(item, key) in searchResults" :key="key" class="block mb-2 mt-2">
              <div class="w-full py-2 whitespace-nowrap text-gray-900">
                <div class="block">
                  <span class="group inline-flex space-x-2 truncate">
                    <span class="text-gray-800 cursor-pointer hover:text-purple-600 font-bold truncate group-hover:text-purple-600">
                      <div v-if="item.type === 0" v-on:click="goToUrl(item.id, 0, formatName(item.name))" class="cursor-pointer hover:text-purple-500">
                        {{ item.name }}
                      </div>
                      <div v-else-if="item.type === 1">
                        <div v-on:click="goToUrl(item.id, 1, formatName(item.companyName))"  class="cursor-pointer hover:text-purple-500">
                          <span v-if="item.companyName">{{ item.companyName }}</span>
                          <span v-if="item.vendor" class="pl-1">paid {{ item.vendor }}</span>
                          <span v-if="item.vendor" class="pl-1">${{ item.usdValue }}</span>
                        </div>
                        <div v-if="item.chainType !== null && item.transactionHash !== null" class="mt-1">
                          <span v-if="item.chainType === 0">
                            <a class="text-blue-500 hover:text-blue-600 text-sm"
                               :href="`https://blockchain.com/btc/tx/${item.transactionHash}`"
                               target="_blank">{{ item.transactionHash }}</a>
                          </span>
                          <span v-if="item.chainType === 1">
                            <a class="text-blue-500 hover:text-blue-600 text-sm"
                               :href="`https://etherscan.io/tx/${item.transactionHash}`"
                               target="_blank">{{ item.transactionHash }}</a>
                          </span>
                          <span v-if="item.chainType === 2">
                            <a class="text-blue-500 hover:text-blue-600 text-sm"
                               :href="`https://bscscan.com/tx/${item.transactionHash}`"
                               target="_blank">{{ item.transactionHash }}</a>
                          </span>
                          <span v-if="item.chainType === 3">
                            <a class="text-blue-500 hover:text-blue-600 text-sm"
                               :href="`https://tronscan.org/#/transaction/${item.transactionHash}`"
                               target="_blank">{{ item.transactionHash }}</a>
                          </span>
                          <span v-if="item.chainType === 4">
                            <a class="text-blue-500 hover:text-blue-600 text-sm"
                               :href="`https://solscan.io/tx/${item.transactionHash}`"
                               target="_blank">{{ item.transactionHash }}</a>
                          </span>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="block text-center mt-8" v-if="searchResults.length > 0">
              <span v-on:click="searchQuery = ''" class="text-blue-500">empty results</span>
            </div>
          </div>
          <div v-else>
            <div class="block">
              No results found.
              <div class="block text-center mt-4">
                <span v-on:click="searchQuery = ''" class="text-blue-500">empty results</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SearchService from "@/service/search";

export default {
  name: "Searchbar.vue",
  data:() => ({
    searchQuery: '',
    searchResults: [],
    service: null,
  }),
  beforeMount: function() {
    this.$root.$on('EmptySearchResult', () => {
      this.searchQuery = '';
      this.searchResults = [];
    });

    this.service = new SearchService();
  },
  methods: {
    search: async function() {
      await this.service.getSearchResults(this.searchQuery)
        .then((response) => {
          this.searchResults = response.data.items;
        }).catch((e) => {
          console.log(e.request);
        });
    },
    goToUrl: function(id, type, compName) {
      this.searchQuery = '';
      if (type === 0) {
        this.$router.push({name: 'BusinessShow', params: {id: id, name: compName}});
      } else if (type === 1) {
        this.$router.push({name: 'ReceiptShow', params: {id: id}});
      }
    },
    formatDollar: function(value) {
      let formatter;
      let currency = 0;
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });

      currency = value;

      if (currency !== 0) {
        return formatter.format(currency);
      }
    },
    formatName: function(name) {
      for (let i = 0; i < name.length; i++) {
        name = name.replace(" ", "-");
      }
      return name.toLowerCase();
    }
  },
}
</script>


