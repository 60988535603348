<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
    <div class="block border bg-white rounded-2xl p-4">
      <div class="space-y-8 divide-y divide-gray-200">
        <div class="">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Add Expense
          </h3>
          <div class="block mt-6">
            <div v-if="accounts.length > 0" class="block">
              <select v-on:change="selectAccount($event)" v-model="accountId" class="w-full block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                <option selected :value="null">Select account (optional)</option>
                <option v-for="(account, key) in accounts" :key="key" :value="account.id">{{account.username}}: {{account.email}}</option>
              </select>
            </div>
            <div :class="{'flex mt-2': accounts.length > 0, 'flex': accounts.length < 1}">
              <div class="flex-1">
              <input v-model="expense"
                     type="text"
                     required
                     placeholder="Create new expense"
                     class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
              </div>
              <div class="pl-5">
                <button v-if="expense !== '' && !loading" v-on:click="createExpense" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md border-gray-800 hover:bg-gray-800 hover:text-white">Create expense</button>
                <button v-else-if="expense !== '' && loading" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                  Create expense
                </button>
                <button v-else class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium rounded-md text-gray-800 bg-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">Create expense</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpenseService from "@/service/expense";
import AccountService from "@/service/account";

export default {
name: "ExpenseHeads",
  data: () => ({
    accountId: null,
    accounts: [],
    expense: '',
    expenses: [],
    loading: false,
  }),
  beforeMount: function() {
    this.getAccountRights();
  },
  methods: {
    getAccountRights: async function() {
      const service = new AccountService();
      // get list of accounts with edit rights for your company
      await service.getAccountRights().then(response => {
        this.accounts = response.data;
      });
    },
    selectAccount: function($event) {
      this.accountId = $event.target.value;
    },
    createExpense: async function() {
      this.loading = !this.loading;
      const service = new ExpenseService();
      const dto = {
        name: this.expense,
        ownerAccountId: this.accountId,
      }

      await service.addHeads(dto)
        .then(async () => {
          this.$notifications(
            'Expense Added',
            '',
            '',
            0,
            true
          );
        }).catch((e) => {
          console.log(e);
        }).finally(() => {
          this.expense = '';
          this.loading = !this.loading;
        });
    }
  }
}
</script>

<style scoped>

</style>
