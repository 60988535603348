<template>
  <div  class="fixed inset-0 overflow-y-auto z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div v-on:click="toggleModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
        <div class="sm:block absolute top-0 right-0 pt-4 pr-4">
          <button v-on:click="toggleModal" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="bg-white h-auto scale-150 overflow-y-auto sm:rounded-lg">
          <div v-if="type === 1">
            <p class="mb-2">
              {{ dto.name }} is used in the following receipts. Please edit these receipts first before deleting {{ dto.name }}
            </p>
            <div v-for="(receipt, key) in usages" :key="key" class="block mb-2">
              <router-link :to="{name: 'ReceiptEdit', params: {id: receipt.receiptId}}" class="text-blue-500 hover:text-blue-600">
                Receipt ID: {{ receipt.receiptId }}
              </router-link>
            </div>
          </div>
          <div v-if="type === 2">
            <p class="mb-2">
              {{ dto.txHash }} is used in the following receipts. Please edit these receipts first before deleting the tx hash
            </p>
            <div v-for="(receipt, key) in usages" :key="key" class="block mb-2">
              <router-link :to="{name: 'ReceiptEdit', params: {id: receipt}}" class="text-blue-500 hover:text-blue-600">
                Receipt ID: {{ receipt }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Usage",
  props: {
    usages: Array,
    dto: Object,
    type: Number
  },
  methods: {
    toggleModal: function() {
      this.$emit('toggleModal');
    }
  }
}
</script>

<style scoped>

</style>
