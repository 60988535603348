<template>
  <div class="bg-white shadow">
    <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
      <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
        <div class="flex-1 min-w-0">          
        </div>
        <div v-if="$route.matched.some(({ name }) => name === 'Business') || $route.matched.some(({ name }) => name === 'BusinessShow')" class="mt-6 h-10 flex space-x-3 md:mt-0 md:ml-4">
          <button v-on:click="toggleAddBusinessModal" class="inline-flex items-center px-4 py-1 border border-blue-500 shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Add Company
          </button>
        </div>
<!--        <div v-if="$route.matched.some(({ name }) => name === 'Profile')" class="mt-6 h-10 flex space-x-3 md:mt-0 md:ml-4">-->
<!--          <a v-if="$route.matched.some(({ name }) => name === 'Profile')" href="/excel/accountchain-excel-example-format.xlsx" download class="inline-flex items-center px-4 py-1 border border-gray-800 shadow-sm text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">-->
<!--            Download Example File-->
<!--          </a>-->
<!--          <button v-if="$route.matched.some(({ name }) => name === 'Profile')" v-on:click="toggleImportExcelModal" class="inline-flex items-center px-4 py-1 border border-blue-500 shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">-->
<!--            Import Expense List-->
<!--          </button>-->
<!--        </div>-->
        <div v-if="$route.matched.some(({ name }) => name === 'Receipt')" class="mt-6 h-10 flex space-x-3 md:mt-0 md:ml-4">
          <router-link v-if="$route.matched.some(({ name }) => name === 'Receipt')" :to="{name: 'ReceiptCreate'}" class="inline-flex items-center px-4 py-1 border border-blue-500 shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Add Receipt
          </router-link>
        </div>
        <div v-if="$route.matched.some(({ name }) => name === 'TxHash')" class="mt-6 h-10 flex space-x-3 md:mt-0 md:ml-4">
          <router-link v-if="$route.matched.some(({ name }) => name === 'TxHash')" :to="{name: 'TxHashCreate'}" class="inline-flex items-center px-4 py-1 border border-blue-500 shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Add Tx Hash
          </router-link>
        </div>
        <div v-if="$route.matched.some(({ name }) => name === 'Employees')" class="mt-6 h-10 flex space-x-3 md:mt-0 md:ml-4">
          <router-link v-if="$route.matched.some(({ name }) => name === 'Employees')" :to="{name: 'EmployeeForm'}" class="inline-flex items-center px-4 py-1 border border-blue-500 shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Add Employee
          </router-link>
        </div>
      </div>
    </div>
    <AddBusiness v-if="showAddBusinessModal" @toggleModal="toggleAddBusinessModal"/>
    <ImportExcel v-if="showImportExcelModal" @toggleModal="toggleImportExcelModal"/>
  </div>
</template>

<script>
import store from "@/store";
import AccountService from "@/service/account";

import AddBusiness from "@/components/modals/business/Form.business";
import ImportExcel from "@/components/modals/receipt/ImportExcel";

export default {
  name: "Header",
  components: {
    AddBusiness,
    ImportExcel
  },
  data: () => ({
    isAuthenticated: store.getters.authenticated,
    username: store.getters.user.username,

    showAddBusinessModal: false,
    showImportExcelModal: false,
  }),
  beforeMount: async function() {
    if (this.isAuthenticated && this.username === null)
      await this.getProfile();

    this.$root.$on('SetPageHeaderUsername', (username) => {
      this.username = username;
    });
  },
  methods: {
    getProfile: async function() {
      const service = new AccountService();
      await service.getProfile()
        .then((response) => {
          this.username = response.data.username;
          this.$store.state.user.email = response.data.email;
          this.$store.state.user.username = response.data.username;
          this.$root.$emit('SetUsername', this.username);
        }).catch((e) => {
          console.log(`there is an error`, e);
        })
    },
    toggleAddBusinessModal: function() {
      this.showAddBusinessModal = !this.showAddBusinessModal;
    },
    toggleImportExcelModal: function() {
      this.showImportExcelModal = !this.showImportExcelModal;
    },
    // downloadExcelExample: function() {
    //   axios.get("@/assets/excel/accountchain-excel-example-format.xlsx", {
    //     responseType: 'blob'
    //   }).then((response) => {
    //       const blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    //       const url = window.URL.createObjectURL(blob);
    //       const link = document.createElement('a');
    //       link.href = url;
    //       link.setAttribute('download', 'accountchain-excel-example-file-format.xlsx');
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     }).catch((e) => {
    //       console.log(e.request);
    //     });
    // }
  }
}
</script>

<style scoped>

</style>
