import axios from "axios";
import store from "@/store";

export default class VendorService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getters.bearerToken}`,
        }
    }

    async add(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/vendor/add`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async all(companyId: number) {
        if (companyId) {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/vendor/lookup?companyId=${companyId}&pageSize=1000`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        } else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/vendor/get-all`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
    }

    async delete(id: number) {
        return await axios.delete(`${process.env.VUE_APP_SERVICE}/vendor/delete/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }
}
