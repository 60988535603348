<template>
  <div class="px-4 sm:px-6 lg:px-8 py-4 lg:max-w-7xl lg:mx-auto">
    <div class="block border bg-white rounded-2xl p-4">
      <div class="space-y-8 divide-y divide-gray-200">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div class="flex">
              <div class="w-32 bg-gray-300 h-4 mt-2 rounded-md flex-1"></div>
              <div class="w-32 bg-gray-300 h-4 mt-2 ml-5 rounded-md"></div>
            </div>
            <hr class="mt-6">
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="grid grid-cols-3 gap-5">
                <div>
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
                <div class="col-span-2">
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
              </div>
              <div class="grid grid-cols-3 gap-5">
                <div>
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
                <div class="col-span-2">
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
              </div>
              <div class="grid grid-cols-3 gap-5">
                <div>
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
                <div class="col-span-2">
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
              </div>
              <div class="grid grid-cols-3 gap-5">
                <div>
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
                <div class="col-span-2">
                  <div class="w-56 ml-4 bg-gray-300 h-auto rounded-full "></div>
                </div>
              </div>
              <div class="grid grid-cols-3 gap-5">
                <div>
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
                <div class="col-span-2">
                  <div class="inline-block w-full">
                      <div class="w-56 bg-gray-300 h-32"></div>
                    </div>
                </div>
              </div>
              <div class="grid grid-cols-3 gap-5">
                <div>
                  <div class="w-64 bg-gray-300 h-4 mt-2 rounded-md "></div>
                </div>
                <div class="col-span-2">
                  <div class="inline-block w-full">
                    <div class="w-56 bg-gray-300 h-32"></div>
                  </div>
                </div>
              </div>
              <div class="pt-5">
                <div class="flex justify-end">
                  <div class="w-12 bg-gray-300 h-4 mt-2 rounded-md "></div>
                  <div class="w-12 bg-gray-300 h-4 mt-2 rounded-md ml-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Receipt"
}
</script>

<style scoped>

</style>
