<template>
  <div>
    <button v-on:click="toggleSidebar" class="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden">
      <span class="sr-only">Open sidebar</span>
      <!-- Heroicon name: outline/menu-alt-1 -->
      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
      </svg>
    </button>
    <!-- Search bar -->
    <div class="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
      <Searchbar class="flex-1 flex" />
      <div class="ml-4 flex items-center md:ml-6">
        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div>
            <button v-on:click="toggleDropdown = !toggleDropdown" type="button" class="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="hidden ml-3 text-gray-700 text-sm font-medium lg:block"><span class="sr-only">Open user menu for </span>{{ username }}</span>
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <div v-if="toggleDropdown" v-on:mouseleave="toggleDropdown = !toggleDropdown" class="origin-top-right z-10 absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
            <router-link :to="{name: 'Profile'}" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" role="menuitem" tabindex="-1">
              Profile
            </router-link>

            <span v-on:click="logout" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" role="menuitem" tabindex="-1">Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Searchbar from "@/components/layouts/cp/Searchbar";
export default {
  name: "Header.vue",
  components: {
    Searchbar
  },
  data:() => ({
    toggleDropdown: false,
    username: '',
  }),
  beforeMount: function() {
    if (this.$store.getters.user.username !== '' && this.$store.getters.user.username !== null) {
      this.username = this.$store.getters.user.username;
      this.$store.state.user.username = this.username;
    } else {
      this.$root.$on('SetUsername', (username) => {
        console.log(username)
        this.username = username;
        this.$store.state.user.username = this.username;
      });
    }

    this.$root.$on('LogoutUser', () => {
      this.logout();
    });
  },
  methods: {
    toggleSidebar: function() {
      this.$emit('toggleSidebar');
    },
    logout: function() {
      localStorage.removeItem('accountant_bearer');
      localStorage.removeItem('accountant_refresh');

      this.$store.state.authenticated = false;
      this.$store.state.bearerToken = '';
      this.$store.state.refreshToken = '';
      this.$store.state.username = null;

      this.$root.$emit('SetAuthentication', false);
      this.$router.push({name: 'Homepage'});
    },
  }
}
</script>

