<template>
<div>

          <table class="min-w-full leading-normal">
						<thead>
							<tr>
								<th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
									COMPANY NAME
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(business, key) in companies" :key="key">
								<td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									<div class="flex items-center">
										<div class="flex-shrink-0 w-10 h-10">
											<img class="w-full h-full rounded-full"
                       src="@/assets/img/blockchain.png" alt="" />
                      
                      </div>
											<div class="ml-3">
												<p class="text-gray-900 whitespace-no-wrap">
										<router-link    :to="{name: 'BusinessPublicShow', params: {id: business.id, name: formatName(business.name)}}"
                    v-if="$route.matched.some(({ name }) => name === 'Homepage') || $route.matched.some(({ name }) => name === 'BusinessPublicShow')">
                  {{ business.name }}
                </router-link>
                <router-link 
                    :to="{name: 'BusinessShow', params: {id: business.id, name: formatName(business.name)}}"
                    v-else-if="$route.matched.some(({ name }) => name === 'Dashboard') || $route.matched.some(({ name }) => name === 'BusinessShow')  || $route.matched.some(({ name }) => name === 'Business')">
                  {{ business.name }}
                </router-link>
												</p>
											</div>
										</div>
								</td>
						
							</tr>
						</tbody>
					</table>

           <div class="px-5 py-5  border-t flex flex-col xs:flex-row  xs:justify-between ">
            <span class="text-xs xs:text-sm text-gray-900">
            <Pagination
              :pagination="pagination"
              :page="page"
              :isLoadingNext="isLoadingNext"
              :isLoadingPrevious="isLoadingPrevious"
             
             
             @nextPage="nextPage" @previousPage="previousPage" @goToPage="goToPage" />
            </span>
						<!-- <div class="inline-flex mt-2 xs:mt-0">
							<button class="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-l">
                Prev
               </button>
							&nbsp; &nbsp;
							<button class="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-r">
                Next
               </button>
						</div> -->
					</div>

</div>
</template>

<script>
import Pagination from "@/components/tables/Pagination";

export default {
name: "BusinessTables.vue",
  props: {
    isLoadingNext: Boolean,
    isLoadingPrevious: Boolean,
    companyArray: Array,
    pagination: Object,
  },
  components: {
    Pagination
  },
  data: () => ({
    page: 1,
  }),
  computed: {
    companies: function() {
      return this.companyArray;
    },
  },
  methods: {
    nextPage: function() {
      this.page++;
      this.pagination.to = this.page * 25;
      this.pagination.show = this.pagination.to - 24;

      if (this.pagination.to > this.pagination.totalItems)
        this.pagination.to = this.pagination.totalItems;

      this.$emit('getCompanies', this.page, 25, true, false);
    },
    goToPage: function(page) {
      this.page = Number(page);
      this.pagination.to = this.page * 100;
      this.pagination.show = this.pagination.to - 99;

      if (this.pagination.to > this.pagination.totalItems)
        this.pagination.to = this.pagination.totalItems;

      this.$emit('getCompanies', page, 100, false, false);
    },
    previousPage: function() {
      this.page--;
      this.pagination.to = this.page * 25;
      this.pagination.show = this.pagination.to - 24;

      if (this.pagination.show === 0)
        this.pagination.show = 1;

      if (this.pagination.to < 25)
        this.pagination.to = 25;

      this.$emit('getCompanies', this.page, 25, false, true);
    },
    editBusiness: function(business) {
      this.$emit('editBusiness', business);
    },
    formatName: function(name) {
      for (let i = 0; i < name.length; i++) {
        name = name.replace(" ", "-");
      }
      return name.toLowerCase();
    }
  },
}
</script>

<style scoped>

</style>
