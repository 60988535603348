<template>
    <div class="px-4 sm:px-6 lg:px-8 py-8 lg:max-w-7xl lg:mx-auto">
        <div class="block border bg-white rounded-2xl p-4">
            <div class="space-y-8 divide-y divide-gray-200">
                <div class="">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Employee details
                    </h3>
                    <div class="block mt-2">
                        <div v-if="employee.ownerEmail"
                            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Owner email
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="grid grid-cols-4 gap-3">
                                    <div class="col-span-2">
                                        <span type="text"
                                            class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">{{
                                                employee.ownerEmail }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                First name
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="grid grid-cols-4 gap-3">
                                    <div class="col-span-2">
                                        <span type="text"
                                            class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">{{
                                                employee.firstName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Middle name
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="grid grid-cols-4 gap-3">
                                    <div class="col-span-2">
                                        <span type="text"
                                            class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">{{
                                                employee.middleName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Last name
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="grid grid-cols-4 gap-3">
                                    <div class="col-span-2">
                                        <span type="text"
                                            class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">{{
                                                employee.lastName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Id number
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="grid grid-cols-4 gap-3">
                                    <div class="col-span-2">
                                        <span type="text"
                                            class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">{{
                                                employee.idNumber }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Position Held
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="grid grid-cols-4 gap-3">
                                    <div class="col-span-2">
                                        <span type="text"
                                            class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">{{
                                                employee.positionHeld }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="employee.idCardUrl"
                            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Id card image
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <div class="inline-block min-w-full">
                                    <div class="max-w-lg mb-4 flex overflow-x-auto">
                                        <div class="w-56 mr-5 rounded-md">
                                            <img :src="employee.idCardUrl" v-on:click="toggleModal(employee.idCardUrl)"
                                                class="w-full h-36 rounded-md cursor-pointer">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ImgViewer v-if="showModal" :url="imgUrl" @toggleModal="toggleModal" />
                </div>
            </div>
        </div>
        <div v-if="payouts.length > 0" class="block border bg-white rounded-2xl p-4">
            <div class="space-y-8 divide-y divide-gray-200">
                <div class="">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Payouts
                    </h3>
                </div>
                <div class="divide-y w-full">
                    <table class="min-w-full leading-normal">
                        <thead>
                            <tr>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Receipt ID
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Amount
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    <select v-model="currency"
                                        class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                                        <option value="AED">AED</option>
                                        <option value="USD">USD</option>
                                        <option value="EUR">EUR</option>
                                    </select>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(payout, key) in payouts" :key="key">
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">{{ payout.receiptId }}</p>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        <span v-if="currency === 'AED'">{{ payout.currencyValue.aed }}</span>
                                        <span v-if="currency === 'USD'">{{ payout.currencyValue.usd }}</span>
                                        <span v-if="currency === 'EUR'">{{ payout.currencyValue.eur }}</span>
                                    </p>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									<p class="text-gray-900 whitespace-no-wrap">
                                    <router-link :to="{name: 'ReceiptShow', params: {id: payout.receiptId}}" class="text-blue-500 hover:text-blue-600">
                                        <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden="" class="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                                        <span class="relative">Show</span>
                                        </span>
                                    </router-link>
                                    &nbsp;
                                    &nbsp;
                                    </p>
								</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EmployeeService from "@/service/employee";
import ImgViewer from '@/components/modals/receipt/ImageViewer';
import FormatHelper from "@/helpers/format";
export default {
    name: "EmployeeDetail",
    components: {
        ImgViewer
    },
    data: () => ({
        loaded: false,
        employee: {
            ownerEmail: null,
            firstName: null,
            middleName: null,
            lastName: null,
            idNumber: null,
            positionHeld: null,
        },
        showModal: false,
        payouts: [],
        currency: 'USD',
    }),
    created: async function () {
        this.id = this.$route.params.id;
        if (this.id) {
            await this.getEmployee();
        }
        this.loaded = true;
    },
    methods: {
        getEmployee: async function () {
            this.loading = true;
            await EmployeeService.get(this.id).then(response => {
                this.employee = response.data;
            });
            await EmployeeService.getPayouts(this.id).then(response => {
                this.setCurrencyValues(response.data);
            });
            this.loading = false;
        },
        toggleModal: function (url = '') {
            this.imgUrl = url;
            this.showModal = !this.showModal;
        },
        setCurrencyValues: async function (payouts) {
            const helper = new FormatHelper();
            this.payouts = await helper.formatCurrencies(payouts);
        },
    },
    watch: {
        currency: {
            handler: function (val) {
                if (val === 'AED') {
                    //this.$root.$emit('setCurrency', 'AED');
                } else if (val === 'USD') {
                    //this.$root.$emit('setCurrency', 'USD');
                } else if (val === 'EUR') {
                    //this.$root.$emit('setCurrency', 'EUR');
                }
            },
            deep: true,
        },
    }
}
</script>
