import axios from "axios";
import store from "@/store";

export default class EmployeeService {
  constructor() {
    axios.defaults.headers.common = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.getters.bearerToken}`,
    }
  }

  static async get(id: number): Promise<never> {
    return await axios.get(`${process.env.VUE_APP_SERVICE}/Employee/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.getters.bearerToken}`,
      },
    });
  }

  static async getPayouts(id: number): Promise<never> {
    return await axios.get(`${process.env.VUE_APP_SERVICE}/Employee/AllPayouts?employeeId=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.getters.bearerToken}`,
      },
    });
  }

  async create(dto: any) {
    const formData = new FormData();    
    formData.append('FirstName', dto.FirstName);
    if(dto.MiddleName){
      formData.append('MiddleName', dto.MiddleName);
    } else{
      formData.append('MiddleName', '');
    }
    formData.append('LastName', dto.LastName);
    formData.append('IdNumber', dto.IdNumber);
    formData.append('PositionHeld', dto.PositionHeld);
    formData.append('IdCardFile', dto.IdCardFile)
    return await axios.post(`${process.env.VUE_APP_SERVICE}/Employee/create`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.getters.bearerToken}`,
      },
    });
  }

  async update(dto: any) {    
    const formData = new FormData();
    formData.append('Id', dto.Id);
    formData.append('FirstName', dto.FirstName);
    if(dto.MiddleName){
      formData.append('MiddleName', dto.MiddleName);
    } else{
      formData.append('MiddleName', '');
    }
    formData.append('LastName', dto.LastName);
    formData.append('IdNumber', dto.IdNumber);
    formData.append('PositionHeld', dto.PositionHeld);
    formData.append('IdCardUrl', dto.IdCardUrl)
    formData.append('IdCardFile', dto.IdCardFile)
    return await axios.post(`${process.env.VUE_APP_SERVICE}/Employee/update`, formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getters.bearerToken}`,
        },
    });
  }

  async all(){
    return await axios.get(`${process.env.VUE_APP_SERVICE}/Employee/All`, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${store.getters.bearerToken}`,
      },
    });
  }

  async allUnpaged(){
    return await axios.get(`${process.env.VUE_APP_SERVICE}/Employee/AllUnpaged`, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${store.getters.bearerToken}`,
      },
    });
  }
}