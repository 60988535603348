import axios from "axios";
import store from "@/store";

export default class ReceiptService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getters.bearerToken}`,
        }
    }

    async getReceiptById(id: number, accessToken?: string) {
        if (accessToken && accessToken !== '') {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/receipt/${id}?accessToken=${accessToken}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/receipt/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
    }

    async getByAccessToken(token: string) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/receipt/by-access-token?accessToken=${token}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async getAllReceipts(page: number, pageSize: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/receipt/all?page=${page}&pageSize=${pageSize}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async getAllForAccount(page: number, pageSize: number, txHashId?: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/receipt/all-account?page=${page}&pageSize=${pageSize}&txHashId=${txHashId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async getAllExpensesForAccount(page: number, pageSize: number, companyId: number) {
        if (companyId) {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/expenses/all?page=${page}&pageSize=${pageSize}&companyId=${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/expenses/all?page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        }
    }

    async getAllAssetsForAccount(page: number, pageSize: number, companyId: number) {
        if (companyId) {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/assets/all?page=${page}&pageSize=${pageSize}&companyId=${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/assets/all?page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        }
    }

    async getAllAdvancedPaymentsForAccount(page: number, pageSize: number, companyId: number) {
        if (companyId) {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/advancedPayment/all?page=${page}&pageSize=${pageSize}&companyId=${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/advancedPayment/all?page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        }
    }

    async getReceiptByCompanyId(page: number, pageSize: number, companyId: number, cached: boolean) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/receipt/all?page=${page}&pageSize=${pageSize}&companyId=${companyId}&cached=${cached}`, {
            headers: {
                'Content-Type': 'application/json',

            },
        });
    }

    async getImageReceipt(dto: FormData) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/image`, dto,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
            responseType: 'blob'
        });
    }

    async getCustomFields(id: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/receipt/get-custom-field-names?companyId=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async addReceipt(dto: any, currency: number) {
        const formData = new FormData();
        formData.append('IsPrivate', dto.isPrivate);
        formData.append('CompanyId', dto.companyId);
        formData.append('VendorId', dto.vendorId);
        if (dto.txHashId) {
            formData.append('TxHashId', dto.txHashId);
        }
        formData.append('Created', `${dto.createdDate}`);
        formData.append('Amount', dto.amount);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append('Currency', currency);
        formData.append('PaymentMethod', dto.payment.method);

        // These fields are not used anymore, but needed by the API
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append('PayedWithoutPayment', true);

        if (dto.payment.method === 3)
            formData.append('PaymentReference', dto.payment.reference);

        if (dto.receiptItems.length > 0) {
            for (let i = 0; i < dto.receiptItems.length; i++) {
                const item = dto.receiptItems[i];
                if (item.description) {
                    formData.append(`Items[${i}].Description`, item.description);
                } else {
                    formData.append(`Items[${i}].Description`, '');
                }
                formData.append(`Items[${i}].Amount`, item.amount);
                if(item.valuation)
                    formData.append(`Items[${i}].Valuation`, item.valuation);
                if(item.valuationDescription)
                    formData.append(`Items[${i}].valuationDescription`, item.valuationDescription);
                formData.append(`Items[${i}].Type`, item.type);
                formData.append(`Items[${i}].ExpenseCategoryId`, item.expenseCategoryId);
                formData.append(`Items[${i}].ExpenseHeadId`, item.expenseId);

                if (item.customFields.length > 0) {
                    for (let y = 0; y < item.customFields.length; y++) {
                        const field = item.customFields[y];
                        formData.append(`Items[${i}].CustomFields[${y}].Name`, field.name);
                        formData.append(`Items[${i}].CustomFields[${y}].Value`, field.value);
                    }
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`Items[${i}].CustomFields`, []);
                }

                if(item.employeePayouts.length > 0){
                    for (let y = 0; y < item.employeePayouts.length; y++) {
                        const field = item.employeePayouts[y];
                        formData.append(`Items[${i}].EmployeePayouts[${y}].EmployeeId`, field.employeeId);
                        formData.append(`Items[${i}].EmployeePayouts[${y}].Amount`, field.amount);
                    }
                }else{
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`Items[${i}].EmployeePayouts`, []);
                }

                if (item.productImages.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    item.productImages.forEach(product => {
                        formData.append(`Items[${i}].ProductImages`, product)
                    });
                }

                if (item.productVideos.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    item.productVideos.forEach(product => {
                        formData.append(`Items[${i}].ProductVideos`, product)
                    });
                }

                if(item.valuationFiles.length > 0){
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore

                    for (let y = 0; y < item.valuationFiles.length; y++) {
                        formData.append(`Items[${i}].ValuationFiles[${y}].File`, item.valuationFiles[y])
                        formData.append(`Items[${i}].ValuationFiles[${y}].Name`, item.valuationFiles[y].NewName)
                    }
                }
            }
        }

        if (dto.customFields.length > 0) {
            for (let i = 0; i < dto.customFields.length; i++) {
                const field = dto.customFields[i];
                formData.append(`CustomFields[${i}].Name`, field.name);
                formData.append(`CustomFields[${i}].Value`, field.value);
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formData.append(`CustomFields`, []);
        }

        // Receipt Files (Images)
        if (dto.proofOfPayments.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.proofOfPayments.forEach(img => {
                formData.append(`ProofOfPaymentImages`, img)
            });
        }

        // Receipt Files (Images)
        if (dto.receiptImages.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.receiptImages.forEach(img => {
                formData.append(`ReceiptImages`, img)
            });
        }

        // Receipt Documents
        if (dto.receiptDocuments.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.receiptDocuments.forEach(doc => {
                formData.append(`ReceiptDocuments`, doc)
            });
        }

        return await axios.post(`${process.env.VUE_APP_SERVICE}/receipt/add`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async updateReceipt(dto: any, currency: number) {
        const formData = new FormData();
        formData.append('ReceiptId', dto.id);
        formData.append('IsPrivate', dto.isPrivate);
        formData.append('CompanyId', dto.companyId);
        if (dto.vendorId)
            formData.append('VendorId', dto.vendorId);
        else if (dto.vendor.id)
            formData.append('VendorId', dto.vendor.id);
        if (dto.txHashId)
            formData.append('TxHashId', dto.txHashId);
        else if (dto.txHash.id)
            formData.append('TxHashId', dto.txHash.id);

        formData.append('Created', `${dto.createdDate}`);
        formData.append('Amount', dto.amount);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append('Currency', currency);
        formData.append('PaymentMethod', dto.payment.method);

        // These fields are not used anymore, but needed by the API
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append('PayedWithoutPayment', true);

        if (dto.payment.method === 3)
            formData.append('PaymentReference', dto.payment.reference);

        if (dto.receiptItems.length > 0) {
            for (let i = 0; i < dto.receiptItems.length; i++) {
                const item = dto.receiptItems[i];
                if (item.id)
                    formData.append(`Items[${i}].Id`, item.id);
                if (item.description) {
                    formData.append(`Items[${i}].Description`, item.description);
                } else {
                    formData.append(`Items[${i}].Description`, '');
                }
                formData.append(`Items[${i}].Amount`, item.amount);
                if(item.valuation)
                    formData.append(`Items[${i}].Valuation`, item.valuation);
                if(item.valuationDescription)
                    formData.append(`Items[${i}].valuationDescription`, item.valuationDescription);
                formData.append(`Items[${i}].Type`, item.type);
                if (item.expenseCategoryId)
                    formData.append(`Items[${i}].ExpenseCategoryId`, item.expenseCategoryId);
                else if (item.expenseCategory.id)
                    formData.append(`Items[${i}].ExpenseCategoryId`, item.expenseCategory.id);
                if (item.expenseId)
                    formData.append(`Items[${i}].ExpenseHeadId`, item.expenseId);
                else if (item.expense.id)
                    formData.append(`Items[${i}].ExpenseHeadId`, item.expense.id);

                const customFields = [];
                // Add existing Custom Fields
                if (item.customFields.length > 0) {
                    for (let y = 0; y < item.customFields.length; y++) {
                        customFields.push(item.customFields[y]);
                    }
                }
                // Add new existing Custom Fields
                if (item.customFieldsNew && item.customFieldsNew.length > 0) {
                    for (let y = 0; y < item.customFieldsNew.length; y++) {
                        customFields.push(item.customFieldsNew[y]);
                    }
                }

                if (customFields.length > 0) {
                    for (let y = 0; y < customFields.length; y++) {
                        const field = customFields[y];
                        formData.append(`Items[${i}].CustomFields[${y}].Name`, field.name);
                        formData.append(`Items[${i}].CustomFields[${y}].Value`, field.value);
                    }
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`Items[${i}].CustomFields`, []);
                }

                const employeePayouts = [];
                //add existing employeePayouts
                if(item.employeePayouts.length > 0){
                    for (let y = 0; y < item.employeePayouts.length; y++) {
                        employeePayouts.push(item.employeePayouts[y]);
                    }
                }
                // Add new employeePayouts
                if (item.employeePayoutsNew && item.employeePayoutsNew.length > 0) {
                    for (let y = 0; y < item.employeePayoutsNew.length; y++) {
                        employeePayouts.push(item.employeePayoutsNew[y]);
                    }
                }
                if (employeePayouts.length > 0) {
                    for (let y = 0; y < employeePayouts.length; y++) {
                        const employeePayout = employeePayouts[y];
                        formData.append(`Items[${i}].EmployeePayouts[${y}].EmployeeId`, employeePayout.employeeId);
                        formData.append(`Items[${i}].EmployeePayouts[${y}].Amount`, employeePayout.amount);
                    }
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`Items[${i}].EmployeePayouts`, []);
                }

                const prodImages = [];
                // Add existing Product Images
                if (item.productImages.length > 0) {
                    for (let y = 0; y < item.productImages.length; y++) {
                        const file = {
                            file: null,
                            url: item.productImages[y]
                        }
                        prodImages.push(file);
                    }
                }
                // Add new Product Images
                if (item.productImagesNew && item.productImagesNew.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    item.productImagesNew.forEach(product => {
                        const file = {
                            file: product,
                            url: null
                        }
                        prodImages.push(file);
                    });
                }

                if (prodImages.length > 0) {
                    for (let y = 0; y < prodImages.length; y++) {
                        if (prodImages[y].url !== null) {
                            formData.append(`Items[${i}].ProductImages[${y}].File`, "");
                            formData.append(`Items[${i}].ProductImages[${y}].Url`, prodImages[y].url);
                        } else if (prodImages[y].file !== null) {
                            formData.append(`Items[${i}].ProductImages[${y}].Url`, "");
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            formData.append(`Items[${i}].ProductImages[${y}].File`, prodImages[y].file, prodImages[y].file.name);
                        }
                    }
                }

                const prodVideos = [];
                // Add existing Product Videos
                if (item.productVideos.length > 0) {
                    for (let i = 0; i < item.productVideos.length; i++) {
                        const file = {
                            file: null,
                            url: item.productVideos[i]
                        }
                        prodVideos.push(file);
                    }
                }

                // Add new images
                if (item.productVideosNew && item.productVideosNew.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    item.productVideosNew.forEach(product => {
                        const file = {
                            file: product,
                            url: null
                        }
                        prodVideos.push(file);
                    });
                }

                if (prodVideos.length > 0) {
                    for (let y = 0; y < prodVideos.length; y++) {
                        if (prodVideos[y].url !== null) {
                            formData.append(`Items[${i}].ProductVideos[${y}].File`, "");
                            formData.append(`Items[${i}].ProductVideos[${y}].Url`, prodVideos[y].url);
                        } else if (prodVideos[y].file !== null) {
                            formData.append(`Items[${i}].ProductVideos[${y}].Url`, "");
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            formData.append(`Items[${i}].ProductVideos[${y}].File`, prodVideos[y].file, prodVideos[y].file.name);
                        }
                    }
                }

                const valuationFiles = [];
                //add existing valuation files
                if(item.valuationFiles.length > 0){
                    for(let i = 0; i < item.valuationFiles.length; i++){
                        const file = {
                            file: null,
                            name: item.valuationFiles[i].name,
                            url: item.valuationFiles[i].url
                        }
                        valuationFiles.push(file);
                    }
                }

                //add new files
                if(item.valuationFilesNew && item.valuationFilesNew.length > 0){
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    item.valuationFilesNew.forEach(newFile => {
                        const file = {
                            file: newFile,
                            name: newFile.NewName,
                            url: null
                        }
                        valuationFiles.push(file);
                    });
                }

                if (valuationFiles.length > 0) {
                    for (let y = 0; y < valuationFiles.length; y++) {
                        if (valuationFiles[y].url !== null) {
                            formData.append(`Items[${i}].ValuationFiles[${y}].File`, "");
                            formData.append(`Items[${i}].ValuationFiles[${y}].Url`, valuationFiles[y].url);
                        } else if (valuationFiles[y].file !== null) {
                            formData.append(`Items[${i}].ValuationFiles[${y}].Url`, "");
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            formData.append(`Items[${i}].ValuationFiles[${y}].File`, valuationFiles[y].file);
                        }
                        if(valuationFiles[y].name !== null)
                            formData.append(`Items[${i}].ValuationFiles[${y}].Name`, valuationFiles[y].name);
                    }
                }

            }
        }

        const customFields = [];
        // Add existing Custom Fields
        if (dto.customFields.length > 0) {
            for (let y = 0; y < dto.customFields.length; y++) {
                customFields.push(dto.customFields[y]);
            }
        }
        // Add new existing Custom Fields
        if (dto.customFieldsNew.length > 0) {
            for (let y = 0; y < dto.customFieldsNew.length; y++) {
                customFields.push(dto.customFieldsNew[y]);
            }
        }

        if (customFields.length > 0) {
            for (let i = 0; i < customFields.length; i++) {
                const field = customFields[i];
                formData.append(`CustomFields[${i}].Name`, field.name);
                formData.append(`CustomFields[${i}].Value`, field.value);
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formData.append(`CustomFields`, []);
        }

        // Add all existing receipt images
        const receiptImages = [];
        if (dto.receiptImages.length > 0) {
            for (let i = 0; i < dto.receiptImages.length; i++) {
                const file = {
                    file: null,
                    url: dto.receiptImages[i]
                }
                receiptImages.push(file);
            }
        }

        // Add new images
        if (dto.receiptImagesNew.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.receiptImagesNew.forEach(product => {
                const file = {
                    file: product,
                    url: null
                }
                receiptImages.push(file);
            });
        }

        if (receiptImages.length > 0) {
            for (let i = 0; i < receiptImages.length; i++) {
                if (receiptImages[i].url !== null) {
                    formData.append(`ReceiptImages[${i}].File`, "");
                    formData.append(`ReceiptImages[${i}].Url`, receiptImages[i].url);
                } else if (receiptImages[i].file !== null) {
                    formData.append(`ReceiptImages[${i}].Url`, "");
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`ReceiptImages[${i}].File`, receiptImages[i].file, receiptImages[i].file.name);
                }
            }
        }

        // Add all existing proof of payments
        const popImages = [];
        if (dto.proofOfPayments.length > 0) {
            for (let i = 0; i < dto.proofOfPayments.length; i++) {
                const file = {
                    file: null,
                    url: dto.proofOfPayments[i]
                }
                popImages.push(file);
            }
        }

        // Add new images
        if (dto.proofOfPaymentsNew.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.proofOfPaymentsNew.forEach(product => {
                const file = {
                    file: product,
                    url: null
                }
                popImages.push(file);
            });
        }

        if (popImages.length > 0) {
            for (let i = 0; i < popImages.length; i++) {
                if (popImages[i].url !== null) {
                    formData.append(`ProofOfPaymentImages[${i}].File`, "");
                    formData.append(`ProofOfPaymentImages[${i}].Url`, popImages[i].url);
                } else if (popImages[i].file !== null) {
                    formData.append(`ProofOfPaymentImages[${i}].Url`, "");
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`ProofOfPaymentImages[${i}].File`, popImages[i].file, popImages[i].file.name);
                }
            }
        }

        // Add all existing proof of payments
        const receiptDocs = [];
        if (dto.receiptDocuments.length > 0) {
            for (let i = 0; i < dto.receiptDocuments.length; i++) {
                const file = {
                    file: null,
                    url: dto.receiptDocuments[i]
                }
                receiptDocs.push(file);
            }
        }

        // Add new images
        if (dto.receiptDocumentsNew.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dto.receiptDocumentsNew.forEach(product => {
                const file = {
                    file: product,
                    url: null
                }
                receiptDocs.push(file);
            });
        }

        if (receiptDocs.length > 0) {
            for (let i = 0; i < receiptDocs.length; i++) {
                if (receiptDocs[i].url !== null) {
                    formData.append(`ReceiptDocuments[${i}].File`, "");
                    formData.append(`ReceiptDocuments[${i}].Url`, receiptDocs[i].url);
                } else if (receiptDocs[i].file !== null) {
                    formData.append(`ReceiptDocuments[${i}].Url`, "");
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formData.append(`ReceiptDocuments[${i}].File`, receiptDocs[i].file, receiptDocs[i].file.name);
                }
            }
        }

        return await axios.post(`${process.env.VUE_APP_SERVICE}/receipt/edit`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async createAccessToken(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/receipt/create-access-token`, dto,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async uploadExcel(dto: FormData, companyId: number) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/receipt/import-excel?companyId=${companyId}`, dto,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async getQrCode(id: number) {
        return await axios.get(`${process.env.VUE_APP_SERVICE}/receipt/generateqr?id=${id}`, {
            responseType: 'text'
        });
    }

    async typeInReceipt(type: number, id: number) {
        if (type === 0) {
            // Payment
            return await axios.get(`${process.env.VUE_APP_SERVICE}/payment/used-in-receipt-check/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        } else if (type === 1) {
            // Expense category
            return await axios.get(`${process.env.VUE_APP_SERVICE}/expensecategories/used-in-receipt-check/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        } else if (type === 2) {
            // Expense heads
            return await axios.get(`${process.env.VUE_APP_SERVICE}/expensehead/used-in-receipt-check/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        } else if (type === 3) {
            // Vendor
            return await axios.get(`${process.env.VUE_APP_SERVICE}/vendor/used-in-receipt-check/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        } else if (type === 4) {
            // TX HASH in payment
            return await axios.get(`${process.env.VUE_APP_SERVICE}/txhash/used-in-receipt-check/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
    }
}
