<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <div v-for="(nav, key) in navigation" :key="key" class="flex items-center space-x-4">
        <li v-if="key === 0">
            <router-link :to="{name: nav.url}" class="text-gray-400 hover:text-gray-500">
              <div>
                <svg class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                <span class="sr-only">Home</span>
              </div>
            </router-link>
        </li>
        <li v-else>
          <div class="flex items-center">
            <svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <div v-if="nav.active">
              <span class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                           v-bind:class="{'text-blue-500': nav.active}">
                {{nav.name}}
              </span>
            </div>
            <div v-else>
              <router-link v-if="nav.params.id" :to="{name: nav.url, params: {id: nav.params.id, name: nav.params.name}}" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                           v-bind:class="{'text-blue-500': nav.active}">
                {{nav.name}}
              </router-link>
              <router-link v-else :to="{name: nav.name}" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                           v-bind:class="{'text-blue-500': nav.active}">
                {{nav.name}}
              </router-link>
            </div>

          </div>
        </li>
      </div>
    </ol>
  </nav>
</template>

<script>
export default {
name: "Breadcrumb",
  props: {
    navigation: Array
  },
  data: () => ({
    isAuthenticated: false,
  }),
  beforeMount() {
    this.isAuthenticated = this.$store.getters.isAuthenticated;
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>
