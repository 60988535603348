import axios from "axios";
import store from "@/store";

export default class ExpenseService {
    constructor() {
        axios.defaults.headers.common = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getters.bearerToken}`,
        }
    }

    async addHeads(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/expensehead/add`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async allHeads(companyId: number) {
        if (companyId) {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/expensehead/lookup?companyId=${companyId}&pageSize=1000`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
        else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/expensehead/get-all`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
    }

    async deleteHead(id: number) {
        return await axios.delete(`${process.env.VUE_APP_SERVICE}/expensehead/delete/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async addCategory(dto: Record<string, unknown>) {
        return await axios.post(`${process.env.VUE_APP_SERVICE}/expensecategories/add`, dto, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }

    async allCategories(companyId: number) {
        if (companyId) {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/expensecategories/lookup?companyId=${companyId}&pageSize=1000`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        } else {
            return await axios.get(`${process.env.VUE_APP_SERVICE}/expensecategories/get-all`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.bearerToken}`,
                },
            });
        }
    }

    async deleteCategory(id: number) {
        return await axios.delete(`${process.env.VUE_APP_SERVICE}/expensecategories/delete/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.bearerToken}`,
            },
        });
    }
}
