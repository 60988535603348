<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  beforeMount: function() {
    const storage = localStorage.getItem('accountant_bearer');
    if (storage) {
      this.setAuthentication();
      this.$root.$emit('UserSignedIn');
    }
  },
  methods: {
    setAuthentication: function() {
      this.$store.state.authenticated = true;
    }
  }
}
</script>

