<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 relative">
    <div class="block" v-for="(payment, key) in payments" :key="key">
      <div class="py-4 border-b">
        <div v-on:click="payment.showFull = !payment.showFull" class="cursor-pointer">
          <div class="flex">
            <div class="flex-1">
              <div class="pl-2">
                <span>{{payment.name}}</span>
              </div>
            </div>
            <div>
              <div class="flex">
                <svg v-if="!payment.showFull" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg  v-if="payment.showFull" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div v-if="payment.showFull" v-on:click="payment.showFull = !payment.showFull" class="pt-2 cursor-pointer">
          <div class="mt-4 pl-2">
            <div class="block">
              Payment:
              <span v-if="payment.type === 5">Crypto payment</span>
              <span v-if="payment.type === 0">Cash payment</span>
              <span v-if="payment.type === 3">Wire transfer payment</span>
              <span v-if="payment.type === 2">Cheque payment</span>
              <span v-if="payment.type === 1">CreditCard payment</span>
              <span v-if="payment.type === 4">Cashier's check</span>
            </div>
            <div v-if="payment.type === 5" class="block mt-1">
              <div class="flex" >
                <div class="w-10">
                  <div v-if="payment.chainType === 0">
                    <img src="@/assets/icons/btc.svg" class="w-6"/>
                  </div>
                  <div v-if="payment.chainType === 1">
                    <img src="@/assets/icons/eth.svg" class="w-6"/>
                  </div>
                  <div v-if="payment.chainType === 2">
                    <img src="@/assets/icons/bnb.svg" class="w-6"/>
                  </div>
                  <div v-if="payment.chainType === 3">
                    <img src="@/assets/icons/tron.svg" class="w-6"/>
                  </div>
                  <div v-if="payment.chainType === 4">
                    <img src="@/assets/icons/sol.svg" class="w-6"/>
                  </div>
                </div>
                <div class="pl-2 text-blue-500">
                  <div v-if="(payment.chainType === 0 || payment.chainType === 1 || payment.chainType === 2 || payment.chainType === 3 || payment.chainType === 4) && payment.txHash">
                    <span v-if="payment.chainType === 0">{{ payment.amount.toFixed(5) }}BTC</span>
                    <span v-else-if="payment.chainType === 1">{{ payment.amount.toFixed(5) }}ETH</span>
                    <span v-else-if="payment.chainType === 2">{{ payment.amount.toFixed(5) }}BNB</span>
                    <span v-else-if="payment.chainType === 3">{{ payment.amount.toFixed(5) }}TRX</span>
                    <span v-else-if="payment.chainType === 4">{{ payment.amount.toFixed(5) }}SOL</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="payment.type === 2" class="block mt-1">
              Reference:
              <span class="text-blue-500">{{payment.reference}}</span>
            </div>
            <div class="block mt-1">
              Amount:
              <span v-if="payment.currency === 0">{{ payment.currencyValue.aed }}</span>
              <span v-if="payment.currency === 1">{{ payment.currencyValue.usd }}</span>
              <span v-if="payment.currency === 2">{{ payment.currencyValue.eur }}</span>
            </div>
          </div>
          <div class="mt-4 pl-2">
            <div class="flex">
              <div>
                <router-link :to="{name: 'PaymentEdit', params: {id: payment.id}}" class="text-blue-500 hover:text-blue-600">Edit</router-link>
              </div>
              <div class="pl-2">
                <button v-on:click="remove(payment.id, payment)" class="bg-red-500 p-1 rounded-md hover:bg-red-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Usage v-if="showUsageModal" :dto="dto" :type="type" :usages="usages" @toggleModal="toggleModal" />

    <Pagination
        :pagination="pagination"
        :page="page"
        :isLoadingNext="isLoadingNext"
        :isLoadingPrevious="isLoadingPrevious"
        @nextPage="nextPage" @previousPage="previousPage" />
  </div>
</template>

<script>
import Pagination from "@/components/tables/Pagination.vue";
import ReceiptService from "@/service/receipt";
import PaymentService from "@/service/payment";
import Usage from "@/components/modals/receipt/Usage";

export default {
name: "PaymentTableMobile.vue",
  props: {
    isLoadingNext: Boolean,
    isLoadingPrevious: Boolean,
    paymentsArray: Array,
    pagination: Object,
    conversion_rates: Object,
  },
  components: {
    Pagination,
    Usage
  },
  data: () => ({
    page: 1,
    dto: {},
    usages: [],
    type: 1,
    showUsageModal: false
  }),
  computed: {
    payments: function() {
      return this.paymentsArray;
    },
  },
  methods: {
    nextPage: function() {
      this.page++;
      this.pagination.to = this.page * 25;
      this.pagination.show = this.pagination.to - 24;

      if (this.pagination.to > this.pagination.totalItems)
        this.pagination.to = this.pagination.totalItems;

      this.$emit('getPayments', this.page, 25, true, false);
    },
    previousPage: function() {
      this.page--;
      this.pagination.to = this.page * 25;
      this.pagination.show = this.pagination.to - 24;

      if (this.pagination.show === 0)
        this.pagination.show = 1;

      if (this.pagination.to < 25)
        this.pagination.to = 25;

      this.$emit('getPayments', this.page, 25, false, true);
    },
    remove: async function(id, dto) {
      // check if it is used in a receipt
      const service = new PaymentService();
      const receiptService = new ReceiptService();
      await receiptService.typeInReceipt(0, id)
          .then((response) => {
            if (response.data.length > 0) {
              this.usages = response.data;
              this.dto = dto;
              this.showUsageModal = true;
            } else {
              // delete
              service.delete(id)
                  .then(() => {
                    this.$notifications('Payment Deleted', 'Successfully deleted the payment', '', 0, true);
                    this.$emit('getPayments', this.page, 25, false, true);
                  })
            }
          });
    },
    toggleModal: function() {
      this.showUsageModal = !this.showUsageModal;
    }
  }
}
</script>

<style scoped>

</style>
