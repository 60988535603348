import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'

import Base from '@/views/auth/Base.vue'
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'
import Confirm from '@/views/auth/Confirm.vue'
import VerifyAccount from '@/views/auth/VerifyAccount.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'

import Index from '@/views/Index.vue'
import Home from '@/views/pages/Home.vue'
import BusinessShow from '@/views/pages/Business.vue'
import ReceiptShow from '@/views/pages/Receipt.vue'

import Business from '@/views/cp/Business/Business.vue'
import Receipt from '@/views/cp/Receipt/Receipt.vue'
import ReceiptCreate from '@/views/cp/Receipt/Form.vue'
import ReceiptEdit from '@/views/cp/Receipt/Form.vue'

import ExpensesHead from '@/views/cp/Expenses/Head.vue'
import ExpensesHeadCreate from '@/views/cp/Expenses/HeadCreate.vue'
import ExpensesCategories from '@/views/cp/Expenses/Categories.vue'
import Vendors from '@/views/cp/Vendor/Vendors.vue'
import Payment from '@/views/cp/Payment/Payment.vue'
import PaymentCreate from '@/views/cp/Payment/PaymentCreate.vue'
import PaymentEdit from '@/views/cp/Payment/PaymentEdit.vue'
import TxHash from '@/views/cp/TxHash/TxHash.vue'
import TxHashForm from '@/views/cp/TxHash/TxHashForm.vue'
import EmployeeCreateForm from '@/views/cp/Employee/EmployeeCreateForm.vue'
import Employees from '@/views/cp/Employee/Employees.vue'
import EmployeeDetail from '@/views/cp/Employee/EmployeeDetail.vue'

import Profile from '@/views/cp/Account/Profile.vue'

import NotFound from '@/views/404.vue';

import jwt_decode from "jwt-decode";
import AuthenticateService from '@/service/authenticate'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '',
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '',
        name: 'Homepage',
        component: Home
      },
      {
        path: 'search',
        name: 'SearchQuery',
        component: Home
      },
      {
        path: 'business/:name/:id',
        name: 'BusinessPublicShow',
        component: BusinessShow,
      },
      {
        path: 'receipt/:id',
        name: 'ReceiptPublicShow',
        component: ReceiptShow,
      },
    ]
  },
  {
    path: '/cp',
    component: Index,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Receipt,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'business',
        name: 'Business',
        component: Business,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'business/:name/:id',
        name: 'BusinessShow',
        component: BusinessShow,
        meta: {
          isAuthenticated: true,
          canRedirect: true,
          useTemplate: true,
        },
      },
      {
        path: 'receipt',
        name: 'Receipt',
        component: Receipt,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
        },
        {
            path: 'txreceipts/:txid',
            name: 'TxReceipts',
            component: Receipt,
            meta: {
                isAuthenticated: true,
                useTemplate: true,
            },
        },
      {
        path: 'receipt/assets',
        name: 'ReceiptAssets',
        component: Receipt,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'receipt/expenses',
        name: 'ReceiptExpenses',
        component: Receipt,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'receipt/create',
        name: 'ReceiptCreate',
        component: ReceiptCreate,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'receipt/:id',
        name: 'ReceiptShow',
        component: ReceiptShow,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'receipt/:id/edit',
        name: 'ReceiptEdit',
        component: ReceiptEdit,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'expenses-head',
        name: 'ExpensesHeads',
        component: ExpensesHead,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'expenses-head/create',
        name: 'ExpensesHeadsCreate',
        component: ExpensesHeadCreate,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'expense-categories',
        name: 'ExpensesCategories',
        component: ExpensesCategories,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: Vendors,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'payment',
        name: 'Payment',
        component: Payment,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'payment/create',
        name: 'PaymentCreate',
        component: PaymentCreate,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'payment/:id',
        name: 'PaymentEdit',
        component: PaymentEdit,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'tx-hash',
        name: 'TxHash',
        component: TxHash,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'tx-hash',
        name: 'TxHashCreate',
        component: TxHashForm,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'tx-hash/:id',
        name: 'TxHashForm',
        component: TxHashForm,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'employee',
        name: 'EmployeeCreate',
        component: EmployeeCreateForm,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'employee/:id',
        name: 'EmployeeForm',
        component: EmployeeCreateForm,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'employees',
        name: 'Employees',
        component: Employees,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
      {
        path: 'employee/detail/:id',
        name: 'EmployeeDetail',
        component: EmployeeDetail,
        meta: {
          isAuthenticated: true,
          useTemplate: true,
        },
      },
    ]
  },
  {
    path: '/auth',
    component: Base,
    children: [
      {
        path: 'sign-in',
        name: 'Login',
        component: Login
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      },{
        path: 'confirm-account',
        name: 'Confirm',
        component: Confirm
      },
      {
        path: 'account/verify-email',
        name: 'VerifyAccount',
        component: VerifyAccount
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      },
      {
        path: 'account/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, _from, next)  => {
  if (to.matched.some((record) => record.meta.isAuthenticated)) {
    const bearerToken = localStorage.getItem('accountant_bearer');
    // Check if user logged in before
    if (bearerToken !== null) {
      const decoded = jwt_decode(bearerToken);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const expDate = new Date(decoded.exp * 1000);
      // if token is expired get new token
      if (new Date > expDate) {
        const refresh = localStorage.getItem('accountant_refresh');
        if (refresh !== null) {
          const service = new AuthenticateService();
          await service.refreshToken(refresh)
            .then((response: any) => {
              store.state.bearerToken = response.data.jwtToken;
              store.state.refreshToken = response.data.refreshToken;
              store.state.authenticated = true;
              localStorage.setItem('accountant_bearer', response.data.jwtToken);
              localStorage.setItem('accountant_refresh', response.data.refreshToken);
              return next();
            }).catch((e: any) => {
              if (e.request.status === 401) {
                localStorage.removeItem('accountant_bearer');
                localStorage.removeItem('accountant_refresh');
                localStorage.removeItem('accountant_email');
                store.state.authenticated = false;
                store.state.bearerToken = '';
                return next('/');
              } else if (e.request.status === 500) {
                const error = JSON.parse(e.request.response);
                if (error.message === "Invalid token") {
                  localStorage.removeItem('accountant_bearer');
                  localStorage.removeItem('accountant_refresh');
                  localStorage.removeItem('accountant_email');
                  store.state.authenticated = false;
                  store.state.bearerToken = '';
                  return next('/404');
                } else {
                  localStorage.removeItem('accountant_bearer');
                  localStorage.removeItem('accountant_refresh');
                  localStorage.removeItem('accountant_email');
                  store.state.authenticated = false;
                  store.state.bearerToken = '';
                  return next('/404');
                }
              }
            });
        }
      } else {
        store.state.authenticated = true;
        store.state.bearerToken = bearerToken;
        const decoded = jwt_decode(bearerToken);
        // Read token data user_id
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.state.user.user_id = decoded.id;
        return next();
      }
    } else {
      if (to.matched.some((record) => record.meta.canRedirect)) {
        return next(`/business/${to.params.name}/${to.params.id}`);
      } else {
        return next('/')
      }
    }
  } else {
    // Proceed to page
    return next();
  }
});

export default router
