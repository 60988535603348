<template>
    <div class="block mt-2">
        <div class="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">            
              <div class="flex-1">
                <input v-model="vendor"
                       type="text"
                       required
                       placeholder="Create New Vendor"
                       class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
              </div>              
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 sm:pb-5">
            <!-- <div class="pl-5"> -->
                <button v-if="vendor !== '' && !loading" v-on:click="createVendor" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md border-gray-800 hover:bg-gray-800 hover:text-white">Create vendor</button>
                <button v-else-if="vendor !== '' && loading" class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                  Create Vendor
                </button>
                <button v-else class="group relative w-full md:w-44 block md:flex justify-center py-2 px-4 border border-gray-800 text-sm font-medium rounded-md text-gray-800 bg-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">Create vendor</button>
              </div>
        <!-- </div> -->
    </div>
</template>

<script>
import VendorService from "@/service/vendor";


export default {
  name: "VendorForm",
  props: {    
    selectType: {
      type: Number,
      required: false,
      default: null
    }
  },  
  data: () => ({
    loading: false,
    vendor: ''
  }),
  methods: {
    createVendor: async function() {
      this.loading = !this.loading;
      const service = new VendorService();
      const dto = {
        name: this.vendor,
        ownerAccountId: null,
      }

      let ownerAccountId;
      this.$emit('getOwnerAccountId', (x)=> ownerAccountId=x);
      dto.ownerAccountId = ownerAccountId;

      await service.add(dto)
          .then(async (response) => {            
            this.$notifications(
                'Vendor Added',
                '',
                '',
                0,
                true
            );
            this.$emit('getCategories', response.data);            
          }).catch((e) => {
            const error = JSON.parse(e.request.response);
            this.$notifications(
                `Something Went Wrong Creating TxHash`,
                `${error.message}. Please try again`,
                '',
                1,
                true
            );
          }).finally(() => {
            this.vendor = '';
            this.loading = !this.loading;
          });
    }
  }
}
</script>

<style scoped>

</style>
