<template>
  <div>


    <div v-if="receipts.length > 0" class="divide-y w-full">
     
					<table class="min-w-full leading-normal">
						<thead>
							<tr>
								<th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
									COMPANY NAME
								</th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
									DATE
								</th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
									RECEIPT ID
								</th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
									RECEIPT HASH
								</th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
									VENDOR
								</th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
									TRANSACTION
								</th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
									VALUE
								</th>
                <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
								<select v-model="currency" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
            <option value="AED">AED</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(receipt, key) in receipts" :key="key">

								<td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									<div class="flex items-center">
										<div class="flex-shrink-0 w-10 h-10">
											<img class="w-full h-full rounded-full"
                        src="@/assets/img/blockchain.png" alt="" />                      
                      </div>
											<div class="ml-3">
												<p class="text-gray-900 whitespace-no-wrap">
													{{ truncateString(receipt.companyName, 6) }}
												</p>
											</div>
										</div>
								</td>
								<td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									<p class="text-gray-900 whitespace-no-wrap">{{ formatDate(receipt.createdDate) }}</p>
								</td>
								<td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									<p class="text-gray-900 whitespace-no-wrap"> <router-link
                  :to="{name: 'ReceiptPublicShow', params: {id: receipt.id}}"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  v-if="$route.matched.some(({ name }) => name === 'Homepage') ||
                  $route.matched.some(({ name }) => name === 'BusinessPublicShow')">
                {{ receipt.id }} (view)
              </router-link>
              <router-link
                  :to="{name: 'ReceiptShow', params: {id: receipt.id}}"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  v-else-if="$route.matched.some(({ name }) => name === 'Dashboard') ||
                  $route.matched.some(({ name }) => name === 'BusinessShow') ||
                  $route.matched.some(({ name }) => name === 'Receipt') ||
                  $route.matched.some(({ name }) => name === 'ReceiptAssets') ||
                  $route.matched.some(({ name }) => name === 'ReceiptExpenses') ||
                  $route.matched.some(({ name }) => name === 'Profile')">
                {{ receipt.id }} (view)
              </router-link> </p>
								</td>
								<td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									<p class="text-gray-900 whitespace-no-wrap">
                  <router-link
                  :to="{name: 'ReceiptPublicShow', params: {id: receipt.id}}"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  v-if="$route.matched.some(({ name }) => name === 'Homepage') ||
                  $route.matched.some(({ name }) => name === 'BusinessPublicShow')">
                {{ truncateString(receipt.identifier, 6) }}
              </router-link>
              <router-link
                  :to="{name: 'ReceiptShow', params: {id: receipt.id}}"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  v-else-if="$route.matched.some(({ name }) => name === 'Dashboard') ||
                  $route.matched.some(({ name }) => name === 'BusinessShow') ||
                  $route.matched.some(({ name }) => name === 'Receipt') ||
                  $route.matched.some(({ name }) => name === 'ReceiptAssets') ||
                  $route.matched.some(({ name }) => name === 'ReceiptExpenses') ||
                  $route.matched.some(({ name }) => name === 'Profile')">
                {{ truncateString(receipt.identifier, 6) }}
               </router-link>
                  </p>
								</td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									
                 <p class="text-gray-900 whitespace-no-wrap" v-if="receipt.vendorName && receipt.vendorName !== null && receipt.vendorName !== ''">
                {{ truncateString(receipt.vendorName, 6) }}
                </p>
                 </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									<div class="text-gray-900 whitespace-no-wrap flex"  v-if="(receipt.chainType === 0 && receipt.txHash) ||
              (receipt.chainType === 1 && receipt.txHash) ||
              (receipt.chainType === 2 && receipt.txHash) ||
              (receipt.chainType === 3 && receipt.txHash) ||
              (receipt.chainType === 4 && receipt.txHash)">
                     
               <div>
                    <div v-if="receipt.chainType === 0">
                      <img src="@/assets/icons/btc.svg" class="w-8"/>
                    </div>
                    <div v-if="receipt.chainType === 1">
                      <img src="@/assets/icons/eth.svg" class="w-8"/>
                    </div>
                     <div v-if="receipt.chainType === 2">
                      <img src="@/assets/icons/bnb.svg" class="w-8"/>
                    </div>
                    <div v-if="receipt.chainType === 3">
                      <img src="@/assets/icons/tron.svg" class="w-8"/>
                    </div>
                    <div v-if="receipt.chainType === 4">
                      <img src="@/assets/icons/sol.svg" class="w-8"/>
                    </div>
                  </div>
                <div class="nTlink nTlinHash">
                  <span v-if="receipt.chainType === 0">
                    <a 
                       :href="`https://blockchain.com/btc/tx/${receipt.txHash}`"
                       target="_blank"> {{ truncateString(receipt.txHash, 8) }}</a>
                  </span>
                  <span v-if="receipt.chainType === 1">
                    <a c
                       :href="`https://etherscan.io/tx/${receipt.txHash}`"
                       target="_blank"> {{ truncateString(receipt.txHash, 8) }}</a>
                  </span>
                  <span v-if="receipt.chainType === 2">
                    <a 
                       :href="`https://bscscan.com/tx/${receipt.txHash}`"
                       target="_blank">{{ truncateString(receipt.txHash, 8) }}</a>
                  </span>
                  <span v-if="receipt.chainType === 3">
                    <a 
                       :href="`https://tronscan.org/#/transaction/${receipt.txHash}`"
                       target="_blank">{{ truncateString(receipt.txHash, 8) }}</a>
                  </span>
                  <span v-if="receipt.chainType === 4">
                    <a 
                       :href="`https://solscan.io/tx/${receipt.txHash}`"
                       target="_blank">{{ truncateString(receipt.txHash, 8) }}</a>
                  </span>
                </div>

                  </div>
                  <div class="flex" v-if="!receipt.txHash">
                        <div v-if="receipt.paymentMethod === 0 || receipt.paymentMethod === 3" class="">
                            <div>
                                <img src="@/assets/icons/cash.svg" class="w-8" />
                            </div>                            
                        </div>
                        <div v-if="receipt.paymentMethod === 2 || receipt.paymentMethod === 4" class="">
                            <div>
                                <img src="@/assets/icons/cheque.svg" class="w-8" />
                            </div>                            
                        </div>
                        <div v-if="receipt.paymentMethod === 1" class="">
                            <div>
                                <img src="@/assets/icons/creditcard.svg" class="w-8" />
                            </div>                            
                        </div>
                        <div v-if="receipt.paymentMethod === 5 && receipt.chainType === 0" class="">
                            <div>
                                <img src="@/assets/icons/btc.svg" class="w-8" />
                            </div>                            
                        </div>
                        <div v-if="receipt.paymentMethod === 5 && receipt.chainType === 1" class="">
                            <div>
                                <img src="@/assets/icons/eth.svg" class="w-8" />
                            </div>                            
                        </div>
                        <div v-if="receipt.paymentMethod === 5 && receipt.chainType === 2" class="">
                            <div>
                                <img src="@/assets/icons/bnb.svg" class="w-8" />
                            </div>                            
                        </div>
                        <div v-if="receipt.paymentMethod === 5 && receipt.chainType === 3" class="">
                            <div>
                                <img src="@/assets/icons/tron.svg" class="w-8" />
                            </div>                            
                        </div>
                        <div v-if="receipt.paymentMethod === 5 && receipt.chainType === 4" class="">
                            <div>
                                <img src="@/assets/icons/sol.svg" class="w-8" />
                            </div>                            
                        </div>
                        <div class="pl-2 pt-1 ">
                            <span>
                                {{mapPaymentMethod(receipt.paymentMethod)}}
                            </span>                            
                        </div>
                    </div>

								</td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
								<p class="text-gray-900 whitespace-no-wrap">

                <span v-if="currency === 'AED'">{{ receipt.currencyValue.aed }}</span>
                <span v-if="currency === 'USD'">{{ receipt.currencyValue.usd }}</span>
                <span v-if="currency === 'EUR'">{{ receipt.currencyValue.eur }}</span>
           
                  </p>
								</td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
									<p class="text-gray-900 whitespace-no-wrap">
                <router-link v-if="receipt.canEdit" :to="{name: 'ReceiptShow', params: {id: receipt.id}}" class="text-blue-500 hover:text-blue-600">
                <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                  <span aria-hidden="" class="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
									<span class="relative">Show</span>
									</span>
                </router-link>
                       &nbsp;
                  &nbsp;
                <router-link v-if="receipt.canEdit" :to="{name: 'ReceiptEdit', params: {id: receipt.id}}" class="text-blue-500 hover:text-blue-600">
                <span class="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                   <span aria-hidden="" class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
									<span class="relative">Edit</span>
									</span>
               </router-link>
               
                  </p>
								</td>
							</tr>
						</tbody>
					</table>

           <div class="px-5 py-5  border-t flex flex-col xs:flex-row  xs:justify-between ">
            <span class="text-xs xs:text-sm text-gray-900">
            <Pagination
              :pagination="pagination"
              :page="page"
              :isLoadingNext="isLoadingNext"
              :isLoadingPrevious="isLoadingPrevious"
             
             
             @nextPage="nextPage" @previousPage="previousPage" @goToPage="goToPage" />
            </span>
						<!-- <div class="inline-flex mt-2 xs:mt-0">
							<button class="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-l">
                Prev
               </button>
							&nbsp; &nbsp;
							<button class="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-r">
                Next
               </button>
						</div> -->
					</div>
   
   </div>


    <div v-if="receipts.length < 1" class="text-center">
      No receipts added yet
    </div>
  


  </div>
</template>

<script>
import Pagination from '@/components/tables/Pagination.vue';

export default {
  name: "ReceiptTable",
  props: {
    isLoadingNext: Boolean,
    isLoadingPrevious: Boolean,
    receiptsArray: Array,
    pagination: Object,
    conversion_rates: Object,
  },
  components: {
    Pagination
  },
  data: () => ({
    currency: 'USD',
    page: 1,
  }),
  computed: {
    receipts: function() {
      return this.receiptsArray;
    },
  },
  methods: {
    truncateString: function(str, num) {
      if (str) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
      }
    },
    mapPaymentMethod: function(num){
      if(num === 0){
        return 'Cash';
      }
      if(num === 1){
        return 'Creditcard';
      }
      if(num === 2){
        return 'Cheque';
      }
      if(num === 3){
        return 'Cash';
      }
      if(num === 4){
        return 'Cheque';
      }
      if(num === 5){
        return 'Crypto';
      }
    },
    nextPage: function() {
      this.page++;
      this.pagination.to = this.page * 100;
      this.pagination.show = this.pagination.to - 99;

      if (this.pagination.to > this.pagination.totalItems)
        this.pagination.to = this.pagination.totalItems;

      this.$emit('getReceipts', this.page, 100, true, false);
    },
    goToPage: function(page) {
      this.page = Number(page);
      this.pagination.to = this.page * 100;
      this.pagination.show = this.pagination.to - 99;

      if (this.pagination.to > this.pagination.totalItems)
        this.pagination.to = this.pagination.totalItems;

      this.$emit('getReceipts', page, 100, false, false);
    },
    previousPage: function() {
      this.page--;
      this.pagination.to = this.page * 100;
      this.pagination.show = this.pagination.to - 99;

      if (this.pagination.show === 0)
        this.pagination.show = 1;

      if (this.pagination.to < 100)
        this.pagination.to = 100;

      this.$emit('getReceipts', this.page, 100, false, true);
    },
    formatDate: function(date) {
      return new Date(date)
        .toLocaleDateString(
          [],
          {
            day: 'numeric',
            year: 'numeric',
            month: 'short',
          });
    },
  },
  watch: {
    currency: {
      handler: function(val) {
        if (val === 'AED') {
          this.$root.$emit('setCurrency', 'AED');
        } else if (val === 'USD') {
          this.$root.$emit('setCurrency', 'USD');
        } else if (val === 'EUR') {
          this.$root.$emit('setCurrency', 'EUR');
        }
      },
      deep: true,
    },
  }
}
</script>

<style scoped>
</style>
