<template>
  <div class="max-w-7xl mx-auto bg-white relative">
    <div v-if="receipts.length > 0">
      <div class="block" v-for="(receipt, key) in receipts" :key="key">
        <div v-on:click="goToReceipt(receipt.id)" class="border-b hover:bg-gray-100 ">
          <div
              class="grid grid-cols-3 gap-2 cursor-pointer px-4 pt-4 pb-2">
            <div>
              <div class="flex">
                <div class="flex-1">
                  <span v-if="currency === 'AED'">{{ receipt.currencyValue.aed }}</span>
                  <span v-if="currency === 'USD'">{{ receipt.currencyValue.usd }}</span>
                  <span v-if="currency === 'EUR'">{{ receipt.currencyValue.eur }}</span>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div v-if="(receipt.chainType === 0 && receipt.txHash) ||
              (receipt.chainType === 1 && receipt.txHash) ||
              (receipt.chainType === 2 && receipt.txHash) ||
              (receipt.chainType === 3 && receipt.txHash) ||
              (receipt.chainType === 4 && receipt.txHash)">
                <div class="flex" v-if="receipt.chainType === 0">
                  <img src="@/assets/icons/btc.svg" class="w-6"/>
                  <a class="text-blue-500 pl-2"
                     :href="`https://blockchain.com/btc/tx/${receipt.txHash}`"
                     target="_blank">{{ truncateString(receipt.txHash, 10) }}</a>
                </div>
                <div class="flex" v-if="receipt.chainType === 1">
                  <img src="@/assets/icons/eth.svg" class="w-6"/>
                  <a class="text-blue-500 pl-2"
                     :href="`https://etherscan.io/tx/${receipt.txHash}`"
                     target="_blank">{{ truncateString(receipt.txHash, 10) }}</a>
                </div>
                <div class="flex" v-if="receipt.chainType === 2">
                  <img src="@/assets/icons/bnb.svg" class="w-6"/>
                  <a class="text-blue-500 pl-2"
                     :href="`https://bscscan.com/tx/${receipt.txHash}`"
                     target="_blank">{{ truncateString(receipt.txHash, 10) }}</a>
                </div>
                <div class="flex" v-if="receipt.chainType === 3">
                  <img src="@/assets/icons/tron.svg" class="w-6"/>
                  <a class="text-blue-500 pl-2"
                     :href="`https://tronscan.org/#/transaction/${receipt.txHash}`"
                     target="_blank">{{ truncateString(receipt.txHash, 10) }}</a>
                </div>
                <div class="flex" v-if="receipt.chainType === 4">
                  <img src="@/assets/icons/sol.svg" class="w-6"/>
                  <a class="text-blue-500 pl-2"
                     :href="`https://solscan.io/tx/${receipt.txHash}`"
                     target="_blank">{{ truncateString(receipt.txHash, 10) }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 pb-2">
            <span v-if="$route.matched.some(({ name }) => name === 'BusinessPublicShow')">
              From {{ receipt.companyName }}
            </span>
            <span v-else>
              {{ receipt.vendorName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  <Pagination
      v-if="receipts.length > 0"
      :pagination="pagination"
      :page="page"
      :isLoadingNext="isLoadingNext"
      :isLoadingPrevious="isLoadingPrevious"
      @nextPage="nextPage" @previousPage="previousPage" />
  </div>
</template>

<script>
import Pagination from "@/components/tables/Pagination.vue";

export default {
name: "Receipt.mobile",
  props: {
    receiptsArray: Array,
    pagination: Object,
    conversion_rates: Object,
    isLoadingNext: Boolean,
    isLoadingPrevious: Boolean,
  },
  components: {
    Pagination
  },
  data: () => ({
    currency: 'USD',
    page: 1,
  }),
  computed: {
    receipts: function() {
      return this.receiptsArray;
    },
  },
  methods: {
    goToReceipt: function(id) {
      if (this.$route.matched.some(({ name }) => name === 'Homepage') || this.$route.matched.some(({ name }) => name === 'BusinessPublicShow')) {
        this.$router.push({name: 'ReceiptPublicShow', params: {id: id}});
      } else {
        this.$router.push({name: 'ReceiptShow', params: {id: id}});
      }
    },
    truncateString: function(str, num) {
      if (str) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
      }
    },
    nextPage: function() {
      this.page++;
      this.pagination.to = this.page * 25;
      this.pagination.show = this.pagination.to - 24;

      if (this.pagination.to > this.pagination.totalItems)
        this.pagination.to = this.pagination.totalItems;

      this.$emit('getReceipts', this.page, 25, true, false);
    },
    previousPage: function() {
      this.page--;
      this.pagination.to = this.page * 25;
      this.pagination.show = this.pagination.to - 24;

      if (this.pagination.show === 0)
        this.pagination.show = 1;

      if (this.pagination.to < 25)
        this.pagination.to = 25;

      this.$emit('getReceipts', this.page, 25, false, true);
    },
  }
}
</script>

<style scoped>

</style>
